export enum NAV_ITEM_TYPE {
  LINK = 'link',
  PARENT = 'parent',
  LOGO = 'sustainability-exchange-logo',
  SUST_SITE_LINK = 'sustainability-site-link',
  COPYRIGHT = 'copyright',
  EXTERNAL_LINK = 'external-link',
  SEARCH = 'search-button',
  HAMBURGER = 'hamburger-button',
  CLOSE = 'close-button',
  SUB_NAV_TITLE = 'subnav-title',
  LOCALE_PICKER = 'locale-picker',
}

export enum NAV_MENU_IDS {
  HEADER = 'header',
  FOOTER = 'footer',
  COOKIE_POLICY = 'l1_footer-cookies_policy',
  PRIVACY_NOTE = 'l1_footer-privacy_notice',
}

export enum NAV_PLACEMENT {
  HEADER = 'header',
  DEVICE_NAV = 'device_nav',
  DEVICE_SUB_NAV = 'device_sub_nav',
  FOOTER = 'footer',
  SUB_NAV = 'sub_nav',
}
