export const DEFAULT_LOCALE = 'en-US';
const ASH_TRIM_PREFIX = 'ashtrim';
export const FOOTER_NAV_ITEMS_UPDATED_EVENT_NAME = `${ASH_TRIM_PREFIX}-footer-nav-items-updated`;
export const HEADER_NAV_ITEMS_LOCAL_STORAGE_KEY = `${ASH_TRIM_PREFIX}-header-nav-menu`;
export const FOOTER_NAV_ITEMS_LOCAL_STORAGE_KEY = `${ASH_TRIM_PREFIX}-footer-nav-menu`;
export const ASX_USER_LOCALE = `${ASH_TRIM_PREFIX}-locale-code`;

// Weblabs
export const ASX_LOCALE_PO_LAUNCH = 'ASX_LOCALE_P0_LAUNCH_1047472';
export const ASX_DEBORAH_LANDING_PAGE = 'ASX_DEBORAH_LANDING_PAGE_1114214';
// TODO: Clean up Branding weblab: https://taskei.amazon.dev/tasks/ASX-367
export const ASX_XCM_BRANDING = 'ASX_XCM_BRANDING_1139149';

// Styling/CSS Values
export const XCM_ROOT_CLASS = 'xcm-upgrade';
export const LINK_CLASS = 'asx-link-text';
