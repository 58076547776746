import classNames from 'classnames';
import { Dispatch, FC, SetStateAction } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import {
  IconType,
  PRIMARY_BUTTON_V2_ICON_POSITION,
  PRIMARY_BUTTON_V2_SIZE,
  PRIMARY_BUTTON_V2_VARIANT,
  PrimaryButtonV2,
} from 'src/components/atoms';
import { HMD_RESOURCE_BUNDLE_NAME, HMDFormStrings } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';

import styles from './RateButton.module.scss';

interface RateButtonProps {
  theme?: SECTION_THEME;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  iconPosition?: PRIMARY_BUTTON_V2_ICON_POSITION;
  buttonSize?: PRIMARY_BUTTON_V2_SIZE;
}

export const RateButton: FC<RateButtonProps> = ({
  theme = SECTION_THEME.Dark,
  setIsModalVisible,
  buttonSize = PRIMARY_BUTTON_V2_SIZE.Small,
  iconPosition = PRIMARY_BUTTON_V2_ICON_POSITION.Before,
}) => {
  const [hmdBundle] = useBundle(HMD_RESOURCE_BUNDLE_NAME);
  const playbookStyle = iconPosition === PRIMARY_BUTTON_V2_ICON_POSITION.After;
  return (
    <PrimaryButtonV2
      data-testid="rate-button"
      className={classNames(styles.rateButton, playbookStyle && styles.playbook)}
      icon={'star' as IconType}
      iconPosition={iconPosition}
      buttonSize={buttonSize}
      isBold={playbookStyle}
      variant={PRIMARY_BUTTON_V2_VARIANT.Rate}
      theme={theme}
      type="button"
      hasMobileHover
      label={t(hmdBundle, HMDFormStrings.RATE_CONTENT_BUTTON_LABEL)}
      onClick={() => setIsModalVisible(true)}
    />
  );
};
