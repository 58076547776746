import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren, useState } from 'react';

import { Heading, HEADING_SIZE, Icon, Tagline } from 'src/components/atoms';
import { BentoCard, Slider } from 'src/components/common';
import { TooltipModalWrapper } from 'src/components/common/tooltip-modal/TooltipModalWrapper';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './BentoCards.module.scss';

interface BentoCardsProps {
  description: string;
  label: string;
  title: string;
  tooltipTheme?: SECTION_THEME;
  tooltip?: {
    image?: ImageAttributes;
    description: string;
    cta: {
      label: string;
      url: string;
    };
  };
  firstCard: {
    label: string;
    title: string;
  };
  secondCard: {
    label: string;
    title: string;
  };
}

const CardsWrapper: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { isSmallDesktop } = useDeviceSize();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (isSmallDesktop && children) return <>{children}</>;

  return <Slider>{children}</Slider>;
};

export const BentoCards: FunctionComponent<BentoCardsProps> = ({
  description,
  label,
  title,
  tooltipTheme = SECTION_THEME.Light,
  tooltip,
  firstCard,
  secondCard,
}) => {
  const [descriptionBegin, highlight, descriptionEnd] = description.split('*');
  const [tooltipIsOpened, setTooltipIsOpened] = useState(false);

  const handleTooltipVisibility = (isVisible: boolean) => setTooltipIsOpened(isVisible);
  return (
    <div className={classNames(styles.bentoCards, styles[tooltipTheme])}>
      <div className={classNames(styles.textContent, styles.gridElement)}>
        <Tagline className={styles.tagline}>{label}</Tagline>
        <Heading className={styles.heading} as="h3" size={HEADING_SIZE.h3}>
          {title}
        </Heading>
        {tooltip ? (
          <p className={styles.description}>
            {descriptionBegin}
            <button
              type="button"
              className={styles.highlight}
              onClick={() => handleTooltipVisibility(true)}
              data-testid="tooltip-highlight-cta"
            >
              {highlight} <Icon className={classNames(styles.icon, tooltipIsOpened && styles.isOpen)} name="info" />
            </button>
            {tooltip && (
              <TooltipModalWrapper
                theme={tooltipTheme}
                isVisible={tooltipIsOpened}
                image={tooltip.image}
                cta={tooltip.cta}
                description={tooltip.description}
                close={() => handleTooltipVisibility(false)}
              />
            )}
            {descriptionEnd}
          </p>
        ) : (
          <p>{description}</p>
        )}
      </div>
      <CardsWrapper>
        <BentoCard className={classNames(styles.card, styles.gridElement)} label={firstCard.label}>
          <Heading className={styles.cardHeading} as="h3" size={HEADING_SIZE.h3}>
            {firstCard.title}
          </Heading>
        </BentoCard>

        <BentoCard
          className={classNames(styles.card, styles.gridElement)}
          label={secondCard.label}
          theme={SECTION_THEME.Dark}
        >
          <Heading className={styles.cardHeading} as="h3" size={HEADING_SIZE.h3}>
            {secondCard.title}
          </Heading>
        </BentoCard>
      </CardsWrapper>
    </div>
  );
};
