import { IconType } from 'src/components/atoms';
import fallbackImage1 from 'src/images/fallback/fallback-image-1.jpg';
import fallbackImage2 from 'src/images/fallback/fallback-image-2.jpg';
import fallbackImage3 from 'src/images/fallback/fallback-image-3.png';

import { QuoteComponentProps } from './QuoteComponent';

export const quoteMock: QuoteComponentProps = {
  title: 'Take action with climate leaders across industries',
  subtitle:
    'You’re in good company. Join these leaders who are taking advantage of our high standards and investing in urgently needed climate solutions.',
  brandTabs: {
    options: [
      {
        brandIcon: 'brandVisa' as IconType,
        profileImage: { src: fallbackImage1, alt: 'Visa Profile Image' },
        profileName: 'John Doe',
        profileOcupation: 'Technology Innovator',
      },
      {
        brandIcon: 'brandPhilips' as IconType,
        profileImage: { src: fallbackImage2, alt: 'Philips Profile Image' },
        profileName: 'Jane Doe',
        profileOcupation: 'Search Engine Giant',
      },
      {
        brandIcon: 'brandUber' as IconType,
        profileImage: { src: fallbackImage3, alt: 'Uber Profile Image' },
        profileName: 'Hugo Doe',
        profileOcupation: 'Technology Innovator',
      },
      {
        brandIcon: 'brandNeste' as IconType,
        profileImage: { src: fallbackImage1, alt: 'Microsoft Profile Image' },
        profileName: 'Sara Doe',
        profileOcupation: 'Software Pioneer',
      },
      {
        brandIcon: 'brandSony' as IconType,
        profileImage: { src: fallbackImage2, alt: 'Sony Profile Image' },
        profileName: 'Rachel Doe',
        profileOcupation: 'Search Engine Giant',
      },
    ],
  },
  quotes: [
    '[1 John Doe]“Lorem ipsum dolor sit amet consectetur. Molestie netus neselit. Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Donec dictum nibh et nisl laoreet mattis ipsum. dictum nibh et nisl laoreet ma”',
    '[2 Jane Doe]“Lorem ipsum dolor sit amet consectetur. Molestie netus neselit. Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Donec dictum nibh et nisl laoreet mattis ipsum. dictum nibh et nisl laoreet ma”',
    '[3 Hugo Doe]“Lorem ipsum dolor sit amet consectetur. Molestie netus neselit. Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Donec dictum nibh et nisl laoreet mattis ipsum. dictum nibh et nisl laoreet ma”',
    '[4 Sara Doe]“Lorem ipsum dolor sit amet consectetur. Molestie netus neselit. Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Donec dictum nibh et nisl laoreet mattis ipsum. dictum nibh et nisl laoreet ma”',
    '[5 Rachel Doe]“Lorem ipsum dolor sit amet consectetur. Molestie netus neselit. Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Donec dictum nibh et nisl laoreet mattis ipsum. dictum nibh et nisl laoreet ma”',
  ],
  buttonLabel: 'Get started',
  imageLeft: { src: fallbackImage2, alt: 'Fallback Image left' },
  imageRight: { src: fallbackImage3, alt: 'Fallback Image right' },
};
