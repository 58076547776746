import { motion } from 'framer-motion';
import { FunctionComponent } from 'react';

import { AsxImage, AsxMarkdownContent } from 'src/components/atoms';
import { TextComponent } from 'src/components/common';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './TextAndCard.module.scss';
import { useTextAndCard } from './TextAndCardAnimations';

export interface TextAndCardProps {
  /* Text component props */
  title: string;
  /* Optional number parameter that indicates how many words should be highlighted starting from the end of the string, 
  for example a 2 indicates that the last 2 words from the string should be highlighted */
  highlitedTitle?: number;
  subTitle: string;
  /* Card props */
  cardTitle: string;
  /* The content of the card itself is split into 2 paragraphs */
  cardCopy: { firstParagraph: string; secondParagraph: string };
  cardImage: ImageAttributes;
  /* Last section props */
  /* This description is the main text content of the last section, it's split in 2 parts, both behaving the same but with
  different colors */
  description: { grey: string; green: string };
  /* Small decorative image */
  smallImage: ImageAttributes;
  /* Big decorative image */
  bigImage: ImageAttributes;
}

// This components acts as a component with 2 different sections, the first one being a text component with a card,
// it's content being plain text and an image, and the second one being accesible by scrolling past the first section
// which is also text but with an small intro animation
export const TextAndCard: FunctionComponent<TextAndCardProps> = ({
  title,
  highlitedTitle = 2,
  subTitle,
  cardTitle,
  cardCopy,
  cardImage,
  description,
  smallImage,
  bigImage,
}) => {
  const { refs } = useTextAndCard();
  const { self, titleCardRef, descriptionRef, smallImageRef, bigImageRef } = refs;
  const { isSmallDesktop } = useDeviceSize();

  return (
    <motion.div className={styles.textAndCard} ref={self}>
      <motion.div className={styles.titleAndCardSection} ref={titleCardRef}>
        <TextComponent
          heading={title}
          highlightWordsCount={highlitedTitle}
          copy={subTitle}
          copyClassName={styles.copy}
          className={styles.textComponent}
          titleAlignment={isSmallDesktop ? 'right' : 'left'}
        />
        <div className={styles.cardWrapper}>
          <div className={styles.cardImageContainer}>
            <AsxImage className={styles.cardImage} alt={cardImage.alt} src={cardImage.src} data-testid="cardImage" />
          </div>
          <div className={styles.cardTextWrapper}>
            <h5 className={styles.cardTitle}>{cardTitle}</h5>
            <div className={styles.cardCopyWrapper}>
              <AsxMarkdownContent className={styles.cardCopy} copy={cardCopy.firstParagraph} />
              <AsxMarkdownContent className={styles.cardCopy} copy={cardCopy.secondParagraph} />
            </div>
          </div>
        </div>
      </motion.div>
      <motion.div className={styles.descriptionSection} ref={descriptionRef}>
        <p className={styles.description}>
          <span className={styles.descriptionGrey}>{description.grey}</span>
          <span className={styles.descriptionGreen}>{description.green}</span>
        </p>
      </motion.div>
      <div className={styles.smallImageWrapper} ref={smallImageRef}>
        <AsxImage className={styles.smallImage} alt={smallImage.alt} src={smallImage.src} data-testid="smallImage" />
      </div>
      <div className={styles.bigImageWrapper} ref={bigImageRef}>
        <AsxImage className={styles.bigImage} alt={bigImage.alt} src={bigImage.src} data-testid="bigImage" />
      </div>
    </motion.div>
  );
};
