import { FunctionComponent } from 'react';

import { MenuItem } from 'src/components/common/menu-item/MenuItem';
import { useFooterNavItems } from 'src/components/footer/hooks/use-footer-nav-items';
import { NAV_MENU_IDS, NAV_PLACEMENT } from 'src/enums/navigation';

import styles from './Footer.module.scss';

export const Footer: FunctionComponent = () => {
  const { navItems } = useFooterNavItems();

  return (
    <footer className={styles.footer}>
      <nav aria-label="secondary" className={styles.navigation}>
        <ul className={styles.listContainer}>
          {navItems.map((item) => (
            <MenuItem
              isAlignedRight={item.id === NAV_MENU_IDS.PRIVACY_NOTE}
              key={item.id}
              navItem={item}
              placement={NAV_PLACEMENT.FOOTER}
            />
          ))}
        </ul>
      </nav>
    </footer>
  );
};
