import classnames from 'classnames';
import React, { FunctionComponent, useState } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import {
  AsxImage,
  AsxMarkdownContent,
  FormattedLink,
  IconType,
  PrimaryButton,
  PrimaryButtonV2,
  Tagline,
} from 'src/components/atoms';
import { RateButton } from 'src/components/atoms/rate-button/RateButton';
import { HmdMetadata, HmdModal } from 'src/components/templates/hmd/HmdModal';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { shouldRenderFluidPageSection, shouldRenderHmd, t } from 'src/helpers';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './ListComponentV2.module.scss';

export enum CTA_TYPE {
  Pdf = 'pdf',
  Link = 'link',
}

export enum CTA_POSITION {
  bottom = 'bottom',
}

export enum LIST_VARIANT {
  List = 'list',
  Accordion = 'accordion',
}

export type ListItemV2Props = {
  theme?: SECTION_THEME;
  title?: string;
  headingSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  subtitle?: string;
  image?: ImageAttributes;
  taglines?: Array<string | undefined>;
  cta?: {
    href: string;
    icon?: IconType;
    type?: CTA_TYPE;
    label?: string;
    position?: CTA_POSITION;
  };
  label?: string;
  links?: { label: string; url: string; icon?: IconType }[];
  isOpen?: boolean;
  variant?: LIST_VARIANT;
  resourceId?: string;
  rateable?: boolean;
  onRateButtonClick?: () => void;
};

export const ListItemV2: FunctionComponent<ListItemV2Props> = ({
  theme = SECTION_THEME.Light,
  image,
  title,
  headingSize = 'h5',
  subtitle,
  taglines,
  cta = { href: undefined, icon: undefined, type: CTA_TYPE.Link, label: undefined, position: undefined },
  label,
  isOpen = false,
  links,
  variant = LIST_VARIANT.List,
  resourceId,
  rateable,
  onRateButtonClick,
}) => {
  const { isDesktop } = useDeviceTracker();

  const HeadingTag = React.createElement(headingSize, { className: classnames(styles[theme], styles.title) }, title);
  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);
  const renderFluidPageSection = shouldRenderFluidPageSection();
  const isRateable = rateable && shouldRenderHmd() && resourceId && onRateButtonClick;

  const tabIndexHidden = -1;
  const tabIndexVisible = 0;

  const handleFooterClick = () => {
    if (cta.href) {
      window.open(cta.href, '_blank');
    }
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // for accessibility reasons we need the link to be the only interactive element but since the whole card is clickable we are going to prevent the clicking of the <a>
    e.preventDefault();
  };

  const handleLinkKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === 'Enter') {
      handleFooterClick();
    }
  };

  const getAriaLabel = () => {
    const exploreText = t(commonBundle, 'explore');
    const newTabText = t(commonBundle, 'new_tab');
    if (cta.type === CTA_TYPE.Pdf) {
      return `${exploreText} ${title} ${t(commonBundle, 'pdf')} ${newTabText}`;
    }
    return `${exploreText} ${title} ${newTabText}`;
  };

  return (
    <div
      key={`${title}-${label}`}
      className={classnames(
        styles.listItem,
        image && styles.hasImage,
        renderFluidPageSection && styles.hasFluidPaddings,
        styles[theme],
        styles[variant]
      )}
    >
      {isDesktop && image && (
        <div className={styles.imageContainer}>
          <AsxImage className={styles.image} alt={image.alt} src={image.src} data-testid="listComponentImage" />
        </div>
      )}
      <div className={styles.cardContent}>
        <div className={classnames(styles.textContentContainer, styles[theme])}>
          {taglines && taglines.length > 0 && (
            <div className={styles.taglines}>
              {taglines?.map((tagline) => tagline && <Tagline key={tagline}>{tagline}</Tagline>)}
            </div>
          )}
          {title && HeadingTag}
          {subtitle && (
            <AsxMarkdownContent
              tabIndex={variant === LIST_VARIANT.Accordion && !isOpen ? tabIndexHidden : tabIndexVisible}
              className={styles.text}
              copy={subtitle}
            />
          )}
          {links &&
            links.map((link) => (
              <FormattedLink
                key={link.label}
                link={link.url}
                text={link.label}
                className={classnames(styles.link, styles[variant])}
                iconName={link.icon}
                tabIndex={variant === LIST_VARIANT.Accordion && !isOpen ? tabIndexHidden : tabIndexVisible}
                openNewTab
              />
            ))}
          {cta.href && cta.position === CTA_POSITION.bottom && (
            <PrimaryButton
              className={styles.buttonLeft}
              data-testid="primaryButton"
              icon={cta.icon ?? 'chevronTopRight'}
              theme={theme}
              label={cta.label}
              link={cta.href}
              download={cta.icon === 'download'}
              aria-label={getAriaLabel()}
              tabIndex={variant === LIST_VARIANT.Accordion && !isOpen ? tabIndexHidden : tabIndexVisible}
            />
          )}
          {isRateable && (
            <div className={styles.rateButtonContainer}>
              <RateButton setIsModalVisible={onRateButtonClick} theme={SECTION_THEME.Teal} />
            </div>
          )}
        </div>
        {cta.href && !cta.position && (
          <div className={styles.lowerSection}>
            <button type="button" onClick={handleFooterClick} className={styles.cardFooter}>
              <div className={styles.cardCta} aria-label={getAriaLabel()}>
                <a data-testid="list-item-link" href={cta.href} onClick={handleLinkClick} onKeyDown={handleLinkKeyDown}>
                  {label && <span className={classnames(styles.label)}>{label}</span>}
                </a>
                <PrimaryButtonV2
                  aria-hidden
                  hasHoverAnimation={false}
                  className={styles.button}
                  data-testid="primaryButton"
                  icon={cta.icon ?? 'chevronRight'}
                  theme={theme}
                  label={cta.label}
                  download={cta.icon === 'download'}
                  tabIndex={variant === LIST_VARIANT.Accordion && !isOpen ? tabIndexHidden : tabIndexVisible}
                />
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

interface ListComponentV2Props extends React.HTMLAttributes<HTMLDivElement> {
  items: Array<ListItemV2Props>;
}

export const ListComponentV2: FunctionComponent<ListComponentV2Props> = ({ items, ...props }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<HmdMetadata | null>(null);

  const handleRateButtonClick = (hmdMetadata: HmdMetadata) => {
    setSelectedItem(hmdMetadata);
    setIsModalVisible(true);
  };

  return (
    <>
      <div
        className={styles.listComponentV2}
        id={styles.listComponent}
        {...(props as React.HTMLAttributes<HTMLDivElement>)}
      >
        <div className={styles.list}>
          {items.map((item) => (
            <ListItemV2
              key={`${item.title}-${item.label}`}
              {...item}
              onRateButtonClick={() =>
                item.resourceId && handleRateButtonClick({ resourceId: item.resourceId, resourceTitle: item.title })
              }
            />
          ))}
        </div>
      </div>
      {selectedItem && (
        <HmdModal
          key={selectedItem.resourceId}
          isVisible={isModalVisible}
          setIsVisible={setIsModalVisible}
          metadata={{ resourceId: selectedItem.resourceId, resourceTitle: selectedItem.resourceTitle }}
        />
      )}
    </>
  );
};
