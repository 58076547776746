import classNames from 'classnames';
import React from 'react';
import Markdown from 'react-markdown';

import styles from './AsxMarkdownContent.module.scss';

interface AsxMarkdownContentProps {
  copy: string;
  className?: string;
  // tabIndex for being able to bypass focus if markdown lives inside a closed accordion
  tabIndex?: number;
}

export const AsxMarkdownContent: React.FC<AsxMarkdownContentProps> = ({ copy, className, tabIndex = 0 }) => {
  // Custom render function for links
  const renderLink = (props: any) => {
    const { children } = props;

    return (
      <a {...props} tabIndex={tabIndex} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  };

  return (
    <Markdown
      className={classNames(styles['markdown-copy'], className)}
      components={{ a: renderLink }} // Use custom link rendering
    >
      {copy}
    </Markdown>
  );
};
