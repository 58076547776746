export const HMDFormStrings = {
  RETURN_TO_EXCHANGE_LABEL: 'returnToExchange_label',
  HOW_SATISFIED_Q_LABEL: 'howSatisfiedQ_label',
  RATING_REASON_Q_LABEL: 'ratingReasonQ_label',
  VERY_UNSATIFIED_LABEL: 'veryUnsatisfied_label',
  NEUTRAL_LABEL: 'neutral_label',
  VERY_SATISFIED_LABEL: 'verySatisfied_label',
  HMD_TITLE_LABEL: 'hmdTitle_label',
  HMD_SUBTITLE_LABEL: 'hmdSubtitle_label',
  OOPS_LABEL: 'oops_label',
  SOMETHING_WENT_WRONG_LABEL: 'somethingWentWrong_label',
  FEEDBACK_THANKS_LABEL: 'feedbackThanks_label',
  FEEDBACK_HELPS_LABEL: 'feedbackHelps_label',
  TRY_LATER_LABEL: 'tryLater_label',
  RATE_CONTENT_BUTTON_LABEL: 'rateContentButton_label',
  OPTIONS_PREFIX: 'optionsPrefix_label',
};

export const HMDReasonOptionLabels = {
  NOT_APPLICABLE_ROLE: 'notApplicableRole_label',
  NOT_APPLICABLE_REGION: 'notApplicableRegion_label',
  NOT_APPLICABLE_ORG: 'notApplicableOrg_label',
  NOT_EASY_TO_UNDERSTAND: 'notEasyToUnderstand_label',
  NOT_APPROPRIATE_DETAIL: 'notAppropriateDetail_label',
  NOT_INCREASED_KNOWLEDGE: 'notIncreasedKnowledge_label',
  APPLICABLE_ROLE: 'applicableRole_label',
  APPLICABLE_REGION: 'applicableRegion_label',
  APPLICABLE_ORG: 'applicableOrg_label',
  EASY_TO_UNDERSTAND: 'easyToUnderstand_label',
  APPROPRIATE_DETAIL: 'appropriateDetail_label',
  INCREASED_KNOWLEDGE: 'increasedKnowledge_label',
  OTHER: 'other_label',
};

export enum HMD_RATING_OPTIONS {
  RATING_1 = '1',
  RATING_2 = '2',
  RATING_3 = '3',
  RATING_4 = '4',
  RATING_5 = '5',
}
