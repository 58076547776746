import { AnimationControls } from 'framer-motion';

export interface AnimationSequenceArgs {
  imageSlideshowControls: AnimationControls;
  finalSlideControls: AnimationControls;
  onScrollTo: (top: number) => void;
  setHasAnimated: (value: boolean) => void;
  setIsAnimationInProgress: (value: boolean) => void;
}

const delay = async (ms: number): Promise<void> => {
  await new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms);
  });
};

export const runAnimationSequence = async ({
  imageSlideshowControls,
  finalSlideControls,
  onScrollTo,
  setHasAnimated,
  setIsAnimationInProgress,
}: AnimationSequenceArgs): Promise<void> => {
  setIsAnimationInProgress(true);

  await imageSlideshowControls.start({
    y: '-100%',
    transition: { duration: 1, ease: 'easeInOut' },
  });

  await delay(1250);

  await finalSlideControls.start({
    width: 'calc(100vw - 64px)',
    height: '100vh',
    marginBottom: '0',
    transition: { duration: 1, ease: 'easeInOut' },
  });

  setTimeout(() => {
    onScrollTo(window.innerHeight);
  }, 0);

  await imageSlideshowControls.start({
    y: '0',
    transition: { duration: 0, ease: 'easeInOut' },
  });

  setHasAnimated(true);
  setIsAnimationInProgress(false);
};
