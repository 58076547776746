import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useController, Control, FieldValues, Path, PathValue, FieldError } from 'react-hook-form';

import { useBundle } from '@amzn/react-arb-tools';

import { Icon } from 'src/components/atoms';
import { CONTACT_FORM_PAGE_BUNDLE_NAME, FormStrings } from 'src/constants';
import { t } from 'src/helpers';

import styles from './DropdownInput.module.scss';

interface DropdownInputProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  label?: string;
  options: { value: string; label: string }[];
  errors?: FieldError;
  isRadioVariant?: boolean;
  onChange?: (value: string) => void;
  required?: string;
}

export const DropdownInput = <T extends FieldValues>({
  name,
  control,
  label,
  options,
  errors,
  isRadioVariant,
  onChange,
  required,
}: DropdownInputProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: required ?? false,
    },
  });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<string | undefined>();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const optionMenuRef = useRef<HTMLUListElement>(null);

  const [formBundle] = useBundle(CONTACT_FORM_PAGE_BUNDLE_NAME);

  const handleOptionClick = (option: { value: string; label: string }) => {
    setSelectedLabel(option.label);
    field.onChange(option.value as PathValue<T, Path<T>>); // Send the value to the form
    if (onChange) {
      onChange(option.value);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const selectedOption = options.find((option) => option.value === field.value);
    setSelectedLabel(selectedOption ? selectedOption.label : undefined); // Update the selected label
  }, [field.value, options]);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  // Handle keyboard events for accessibility
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      toggleDropdown();
    } else if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  const handleOptionKeyDown = (event: React.KeyboardEvent<HTMLLIElement>, option: { value: string; label: string }) => {
    if (event.key === 'Enter') {
      handleOptionClick(option);
    }
  };

  const handleLastOptionBlur = (event: React.FocusEvent<HTMLLIElement>) => {
    const { relatedTarget } = event;
    if (!dropdownRef.current?.contains(relatedTarget) && !optionMenuRef.current?.contains(relatedTarget)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutsideDropdown = dropdownRef.current && !dropdownRef.current.contains(event.target as Node);
      const isClickOutsideOptions = optionMenuRef.current && !optionMenuRef.current.contains(event.target as Node);
      if (isClickOutsideDropdown && isClickOutsideOptions) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={classNames(styles.dropdownInputContainer, errors && styles.error)}>
      <label className={classNames(styles.label, isRadioVariant && styles.round)} htmlFor={name}>
        {label}
      </label>
      <div
        className={styles.dropdown}
        onClick={toggleDropdown}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsOpen(false)}
        ref={dropdownRef}
        role="button"
        tabIndex={0}
        data-testid="dropdown-input"
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        {isRadioVariant && selectedLabel === undefined && (
          <p className={styles.selectedOption}>{t(formBundle, FormStrings.SELECT_OPTION)}</p>
        )}
        <p className={styles.selectedOption} data-testid="dropdown-selected-label">
          {selectedLabel}
        </p>
        <Icon className={classNames(styles.icon, isOpen && styles.open)} name="chevronRight" />
      </div>
      {isOpen && (
        <ul
          className={classNames(styles.optionsContainer, isRadioVariant && styles.round)}
          ref={optionMenuRef}
          role="menu"
        >
          {options.map((option, index) => (
            <li
              className={classNames(
                styles.option,
                isRadioVariant ? styles.round : styles.check,
                option.value === field.value && styles.active
              )}
              key={option.value}
              role="menuitem"
              onClick={() => handleOptionClick(option)}
              onKeyDown={(event) => handleOptionKeyDown(event, option)}
              onBlur={index === options.length - 1 ? handleLastOptionBlur : undefined}
              tabIndex={0} // Make options focusable
            >
              {isRadioVariant && (
                <div className={styles.optionRoundUncheck}>
                  {option.value === field.value && <Icon className={styles.optionRoundCheck} name="roundCheck" />}
                </div>
              )}
              <p className={styles.optionLabel}>{option.label}</p>
              {!isRadioVariant && option.value === field.value && <Icon className={styles.optionIcon} name="check" />}
            </li>
          ))}
        </ul>
      )}
      {error && (
        <div className={styles.errorWrapper}>
          <Icon name="error" className={styles.errorIcon} />
          <p className={styles.errorMessage}>{error.message}</p>
        </div>
      )}
    </div>
  );
};
