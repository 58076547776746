import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useBundle } from '@amzn/react-arb-tools';

import { MODAL_VARIANT, ModalWrapper } from 'src/components/common';
import {
  HmdFormValues,
  HmdSubmitButton,
  ModalHmdContent,
} from 'src/components/common/modal/content/hmd/ModalHmdContent';
import { HMD_RESOURCE_BUNDLE_NAME, HMDFormStrings } from 'src/constants';
import { t } from 'src/helpers';

export enum STATE {
  normal = 'normal',
  busy = 'busy', // during API call for submission
  success = 'success', // after submission succeeds
  error = 'error', // in case submission fails
}

export type HmdMetadata = {
  resourceId: string;
  resourceTitle?: string;
};

interface HmdModalProps {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  metadata: HmdMetadata;
}

export const HmdModal: FC<HmdModalProps> = ({ isVisible, setIsVisible, metadata }) => {
  const methods = useForm<HmdFormValues>({
    reValidateMode: 'onSubmit',
  });
  const [hmdBundle] = useBundle(HMD_RESOURCE_BUNDLE_NAME);

  const { reset } = methods;

  const [state, setState] = useState<STATE>(STATE.normal);
  const hasNormalState = state === STATE.normal;

  const handleClose = useCallback(() => {
    setState(STATE.normal);
    reset();
    setIsVisible(false);
  }, [setState, reset, setIsVisible]);

  return (
    <FormProvider {...methods}>
      <ModalWrapper
        {...(hasNormalState && {
          title: t(hmdBundle, HMDFormStrings.HMD_TITLE_LABEL),
          subtitle: t(hmdBundle, HMDFormStrings.HMD_SUBTITLE_LABEL),
          footer: <HmdSubmitButton resourceId={metadata.resourceId} />,
        })}
        variant={MODAL_VARIANT.form}
        isVisible={isVisible}
        close={handleClose}
        key={metadata.resourceId}
      >
        <ModalHmdContent
          key={metadata.resourceId}
          metadata={metadata}
          state={state}
          setState={setState}
          close={handleClose}
          methods={methods}
        />
      </ModalWrapper>
    </FormProvider>
  );
};
