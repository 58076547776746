import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { useWeblab } from '@amzn/sustainability-central-shared-react';

import { ASX_XCM_BRANDING } from 'src/constants';
import { THEME } from 'src/enums/theme';
import amzLogoBlack from 'src/images/Amazon_Logo_Black.png';
import amzLogoWhite from 'src/images/Amazon_Logo_White.png';
import logoSeaFoam from 'src/images/Amazon_Sustainability_Exchange_Logo_Sea_Foam.png';
import logoTeal from 'src/images/Amazon_Sustainability_Exchange_Logo_Teal.png';
import logoWhite from 'src/images/Amazon_Sustainability_Exchange_Logo_White.png';

import styles from './Logo.module.scss';

type StyleVariant = 'primary' | 'secondary';

interface LogoProps {
  altText: string;
  href: string;
  styleVariant?: StyleVariant;
  theme?: THEME;
}

export const Logo: FunctionComponent<LogoProps> = ({
  altText,
  href,
  styleVariant = 'primary',
  theme = THEME.LIGHT,
}) => {
  const isBrandingWeblabOn = useWeblab(ASX_XCM_BRANDING) === 'T1';

  let srcImage;
  if (theme === THEME.DARK) {
    srcImage = isBrandingWeblabOn ? amzLogoWhite : logoWhite;
  } else if (styleVariant === 'primary') {
    srcImage = isBrandingWeblabOn ? amzLogoBlack : logoTeal;
  } else {
    srcImage = isBrandingWeblabOn ? amzLogoWhite : logoSeaFoam;
  }

  return (
    <a className={styles.link} href={href}>
      <img alt={altText} className={classNames(styles.logo, { [styles.amzLogo]: isBrandingWeblabOn })} src={srcImage} />
    </a>
  );
};
