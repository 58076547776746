import { FunctionComponent } from 'react';

import { KatProgress } from '@amzn/katal-react';

import { getTreatment, WEBLAB_NAME, WEBLAB_TREATMENT } from 'src/clients/weblab-client';
import { IconType } from 'src/components/atoms';
import {
  AnchorLinks,
  Card,
  ListComponent,
  ListItemProps,
  MainBanner,
  PageSection,
  SecondaryHero,
  Slider,
  TextComponent,
} from 'src/components/common';
import { useDataInitiativeSections } from 'src/components/pages/data-initiative/hooks/useDataInitiativeSections';
import { ClimatePledgeSection } from 'src/components/templates/ClimatePledgeSection';
import { CLOUDFRONT_PREFIX } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';

export const DataInitiativePage: FunctionComponent = () => {
  const { isBundleLoading, dataInitiativeBundle } = useDataInitiativeSections();
  const { isMobile } = useDeviceTracker();
  const shouldRenderASDIHackathon = WEBLAB_TREATMENT.T1 === getTreatment(WEBLAB_NAME.ASX_ASDI_HACKATHON_LAUNCH);

  const imageVariant = isMobile ? 'mobile' : 'desktop';
  const imageRoot = `${CLOUDFRONT_PREFIX}/asdi/locale/en-us/resource-type/image/variation/${imageVariant}`;

  const getInvolvedSectionItems = () => {
    const list: ListItemProps[] = [];

    if (shouldRenderASDIHackathon) {
      list.push({
        title: t(dataInitiativeBundle, 'datainitiative-getinvolved-1_getinvolvedTitle1'),
        subtitle: t(dataInitiativeBundle, 'datainitiative-getinvolved-1_getinvolvedDescription1'),
        cta: {
          href: 'https://zindi.africa/ai-equity-challenge',
          icon: 'chevronTopRight' as IconType,
        },
        image: {
          src: `${imageRoot}/asdi-getinvolved-1.png`,
          alt: t(dataInitiativeBundle, 'datainitiative-getinvolved-1_getinvolvedImageAlt1'),
        },
      });
    }

    list.push(
      ...[
        {
          title: t(dataInitiativeBundle, 'datainitiative-getinvolved-1_getinvolvedTitle2'),
          subtitle: t(dataInitiativeBundle, 'datainitiative-getinvolved-1_getinvolvedDescription2'),
          cta: {
            href: 'https://aws.amazon.com/opendata/open-data-sponsorship-program/',
            icon: 'chevronTopRight' as IconType,
          },
          image: {
            src: `${imageRoot}/asdi-getinvolved-2.png`,
            alt: t(dataInitiativeBundle, 'datainitiative-getinvolved-1_getinvolvedImageAlt2'),
          },
        },
        {
          title: t(dataInitiativeBundle, 'datainitiative-getinvolved-1_getinvolvedTitle3'),
          subtitle: t(dataInitiativeBundle, 'datainitiative-getinvolved-1_getinvolvedDescription3'),
          cta: {
            href: 'https://aws.amazon.com/government-education/sustainability-research-credits/',
            icon: 'chevronTopRight' as IconType,
          },
          image: {
            src: `${imageRoot}/asdi-getinvolved-3.png`,
            alt: t(dataInitiativeBundle, 'datainitiative-getinvolved-1_getinvolvedImageAlt3'),
          },
        },
        {
          title: t(dataInitiativeBundle, 'datainitiative-getinvolved-1_getinvolvedTitle4'),
          subtitle: t(dataInitiativeBundle, 'datainitiative-getinvolved-1_getinvolvedDescription4'),
          cta: {
            href: 'https://registry.opendata.aws/tag/sustainability/usage-examples/index.html',
            icon: 'chevronTopRight' as IconType,
          },
          image: {
            src: `${imageRoot}/asdi-getinvolved-4.png`,
            alt: t(dataInitiativeBundle, 'datainitiative-getinvolved-1_getinvolvedImageAlt4'),
          },
        },
      ]
    );
    return list;
  };

  if (isBundleLoading || !dataInitiativeBundle) {
    return <KatProgress indeterminate type="circular" />;
  }

  return (
    <>
      {/* SECTION: SECONDARY HERO */}
      <PageSection id="data-initiative-secondary-hero">
        <SecondaryHero
          theme={SECTION_THEME.Light}
          heading={t(dataInitiativeBundle, 'datainitiative_title')}
          copy={t(dataInitiativeBundle, 'datainitiative_description')}
          image={{
            alt: t(dataInitiativeBundle, 'datainitiative_heroImageAlt'),
            src: `${imageRoot}/asdi-secondaryhero-1.png`,
          }}
          link={{
            text: t(dataInitiativeBundle, 'datainitiative_heroLinkText'),
            url: 'https://registry.opendata.aws/collab/asdi/',
            iconName: 'chevronTopRight' as IconType,
          }}
        />
      </PageSection>

      <AnchorLinks />

      {/* SECTION: OVERVIEW */}
      <PageSection theme="green-primary" data-anchor id={t(dataInitiativeBundle, 'datainitiative-overview-1_anchorId')}>
        <MainBanner
          theme={SECTION_THEME.Dark}
          title={t(dataInitiativeBundle, 'datainitiative-overview-1_title')}
          accordions={[
            {
              title: t(dataInitiativeBundle, 'datainitiative-overview-1_overviewTitle1'),
              content: [{ text: t(dataInitiativeBundle, 'datainitiative-overview-1_overviewDescription1') }],
            },
            {
              title: t(dataInitiativeBundle, 'datainitiative-overview-1_overviewTitle2'),
              content: [{ text: t(dataInitiativeBundle, 'datainitiative-overview-1_overviewDescription2') }],
            },
            {
              title: t(dataInitiativeBundle, 'datainitiative-overview-1_overviewTitle3'),
              content: [{ text: t(dataInitiativeBundle, 'datainitiative-overview-1_overviewDescription3') }],
            },
          ]}
          image={{
            src: `${imageRoot}/asdi-features-1.png`,
            alt: t(dataInitiativeBundle, 'datainitiative-overview-1_imageAlt'),
          }}
          cta={{
            icon: 'chevronRight' as IconType,
            href: 'https://registry.opendata.aws/collab/asdi/',
            label: t(dataInitiativeBundle, 'datainitiative-overview-1_linkText'),
          }}
        />
      </PageSection>

      {/* SECTION: SPOTLIGHT */}
      <PageSection
        theme="slate-dark"
        data-anchor
        id={t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_anchorId')}
      >
        <Slider
          displayContents
          size="large"
          theme={SECTION_THEME.Dark}
          heading={t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_title')}
          description={t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_description')}
        >
          <Card
            theme={SECTION_THEME.Dark}
            pill={t(dataInitiativeBundle, 'spotlight_pill')}
            image={{
              src: `${imageRoot}/asdi-innovationspotlights-1.png`,
              alt: t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_spotlightImageAlt1'),
            }}
            title={t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_spotlightTitle1')}
            subtitle={t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_spotlightDescription1')}
            icon={'chevronTopRight' as IconType}
            link={{
              label: t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_spotlightLinkLabel1'),
              url: 'https://aws.amazon.com/blogs/publicsector/how-african-leaders-use-open-data-to-fight-deforestation-and-illegal-mining/',
            }}
          />
          <Card
            theme={SECTION_THEME.Dark}
            pill={t(dataInitiativeBundle, 'spotlight_pill')}
            image={{
              src: `${imageRoot}/asdi-innovationspotlights-2.png`,
              alt: t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_spotlightImageAlt2'),
            }}
            title={t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_spotlightTitle2')}
            subtitle={t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_spotlightDescription2')}
            icon={'chevronTopRight' as IconType}
            link={{
              label: t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_spotlightLinkLabel2'),
              url: 'https://geopixel.xyz/',
            }}
          />
          <Card
            theme={SECTION_THEME.Dark}
            pill={t(dataInitiativeBundle, 'spotlight_pill')}
            image={{
              src: `${imageRoot}/asdi-innovationspotlights-3.png`,
              alt: t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_spotlightImageAlt3'),
            }}
            title={t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_spotlightTitle3')}
            subtitle={t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_spotlightDescription3')}
            icon={'chevronTopRight' as IconType}
            link={{
              label: t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_spotlightLinkLabel3'),
              url: 'https://www.element84.com/projects/model-my-watershed/',
            }}
          />
          <Card
            theme={SECTION_THEME.Dark}
            pill={t(dataInitiativeBundle, 'spotlight_pill')}
            image={{
              src: `${imageRoot}/asdi-innovationspotlights-4.png`,
              alt: t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_spotlightImageAlt4'),
            }}
            title={t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_spotlightTitle4')}
            subtitle={t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_spotlightDescription4')}
            icon={'chevronTopRight' as IconType}
            link={{
              label: t(dataInitiativeBundle, 'datainitiative-innovationspotlights-1_spotlightLinkLabel4'),
              url: 'https://www.ocearch.org/tracker/',
            }}
          />
        </Slider>
      </PageSection>

      {/* SECTION: FEATURED DATA */}
      <PageSection data-anchor id={t(dataInitiativeBundle, 'datainitiative-featureddata-1_anchorId')}>
        <Slider
          size="small"
          theme={SECTION_THEME.Light}
          heading={t(dataInitiativeBundle, 'datainitiative-featureddata-1_title')}
          description={t(dataInitiativeBundle, 'datainitiative-featureddata-1_description')}
        >
          <Card
            theme={SECTION_THEME.Light}
            title={t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataTitle1')}
            subtitle={t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataDescription1')}
            icon={'chevronTopRight' as IconType}
            link={{
              label: t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataLinkLabel1'),
              url: 'https://registry.opendata.aws/collab/nasa/',
            }}
          />
          <Card
            theme={SECTION_THEME.Light}
            title={t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataTitle2')}
            subtitle={t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataDescription2')}
            icon={'chevronTopRight' as IconType}
            link={{
              label: t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataLinkLabel2'),
              url: 'https://registry.opendata.aws/collab/noaa/',
            }}
          />
          <Card
            theme={SECTION_THEME.Light}
            title={t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataTitle3')}
            subtitle={t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataDescription3')}
            icon={'chevronTopRight' as IconType}
            link={{
              label: t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataLinkLabel3'),
              url: 'https://registry.opendata.aws/?search=managedBy:met%20office',
            }}
          />
          <Card
            theme={SECTION_THEME.Light}
            title={t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataTitle4')}
            subtitle={t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataDescription4')}
            icon={'chevronTopRight' as IconType}
            link={{
              label: t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataLinkLabel4'),
              url: 'https://registry.opendata.aws/nsf-ncar-era5/',
            }}
          />
          <Card
            theme={SECTION_THEME.Light}
            title={t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataTitle5')}
            subtitle={t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataDescription5')}
            icon={'chevronTopRight' as IconType}
            link={{
              label: t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataLinkLabel5'),
              url: 'https://registry.opendata.aws/?search=managedBy:national%20renewable%20energy%20laboratory',
            }}
          />
          <Card
            theme={SECTION_THEME.Light}
            title={t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataTitle6')}
            subtitle={t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataDescription6')}
            icon={'chevronTopRight' as IconType}
            link={{
              label: t(dataInitiativeBundle, 'datainitiative-featureddata-1_featuredDataLinkLabel6'),
              url: 'https://registry.opendata.aws/collab/deafrica/',
            }}
          />
        </Slider>
      </PageSection>

      {/* SECTION: GET INVOLVED */}
      <PageSection data-anchor id={t(dataInitiativeBundle, 'datainitiative-getinvolved-1_anchorId')}>
        <TextComponent
          theme={SECTION_THEME.Light}
          heading={t(dataInitiativeBundle, 'datainitiative-getinvolved-1_title')}
          copy={t(dataInitiativeBundle, 'datainitiative-getinvolved-1_description')}
        />
        <ListComponent items={getInvolvedSectionItems()} />
      </PageSection>
      <ClimatePledgeSection />
    </>
  );
};
