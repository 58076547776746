import classNames from 'classnames';
import { type ReactElement } from 'react';

import { Icon } from 'src/components/atoms';
import { IconType } from 'src/components/atoms/icons/icons';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';

import styles from './SelectionPill.module.scss';

interface SelectionPillProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The ASX theme to render the component with
   */
  theme?: SECTION_THEME;
  /**
   * Optional className to apply to the component
   */
  className?: string;
  /**
   * The label to display in the pill element
   */
  label: string;
  /**
   * The icon to display in the pill element
   */
  icon: IconType;
  /**
   * Whether the pill element is active or not
   */
  isActive: boolean;
  /**
   * Optional onClick handler to be invoked when the pill is clicked
   */
  onClick?: () => void;
}

/**
 * Renders a clickable pill element with active or inactive states.
 */
export function SelectionPill({
  theme = SECTION_THEME.Dark,
  className,
  label,
  icon,
  isActive,
  onClick,
  ...props
}: SelectionPillProps): ReactElement {
  return (
    <button
      type="button"
      role="tab"
      aria-controls={`tabpanel-${label}`}
      aria-selected={isActive}
      className={classNames(styles.SelectionPill, styles[theme], isActive && styles.isActive, className)}
      onClick={onClick}
      {...props}
    >
      <Icon className={styles.icon} name={icon} />
      <p className={styles.label}>{label}</p>
    </button>
  );
}
