import { FunctionComponent } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { CONTACT_FORM_PAGE_BUNDLE_NAME, FormStrings } from 'src/constants';
import { t } from 'src/helpers';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';

import { NavigationTabsDesktop } from './desktop/NavigationTabsDesktop';
import { NavigationTabsMobile } from './mobile/NavigationTabsMobile';

export type TabContent = {
  /**
   * Object represting the image displayed in the tab
   */
  image: {
    /*
     String source url where the image is hosted
    */
    src: string;
    /*
     String alt text for a11y purposes
    */
    alt: string;
  };
  /**
   * String label displayed in the tab
   */
  label: string;
};

export interface NavigationTabsProps {
  activeTab: number;
  tabs: TabContent[];
  onTabChange(index: number): void;
}

export const NavigationTabs: FunctionComponent<NavigationTabsProps> = (props) => {
  const { isDesktop } = useDeviceTracker();
  const [formBundle] = useBundle(CONTACT_FORM_PAGE_BUNDLE_NAME);

  if (isDesktop) return <NavigationTabsDesktop {...props} />;

  // TODO: Replace this mocked string with the project debora resource one SIM: https://issues.amazon.com/issues/ASX-332
  return <NavigationTabsMobile modalTitle={t(formBundle, FormStrings.SELECT_OPTION)} {...props} />;
};
