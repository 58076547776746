// Contact Form Panther Labels
// TODO rename to form strings
import { PageIngress } from '@amzn/coral_com-amazon-sssapinterface-model-structures-form';

import { FOCUS_AREA_URL } from 'src/components/pages/resource-pages/focus-areas/focusAreaPath';
import { ROUTES } from 'src/data/enums/Routes';

export const FormStrings = {
  REQUIRED_FIELD_PLACEHOLDER_LABEL: 'requiredField_label',
  REQUIRED_FIELD_LABEL_ALT: 'requiredFieldAlt_label',
  NAME_PLACEHOLDER_LABEL: 'fullName_label',
  EMAIL_PLACEHOLDER_LABEL: 'email_label',
  FORM_SUBMIT_BUTTON_LABEL: 'formSubmit_label',
  FORM_SUBMIT_PRIVACY_NOTICE_LABEL: 'privacyNotice_label',
  FORM_SUBMIT_PRIVACY_NOTICE_LABEL_SHORT: 'privacyNoticeShort_label',
  THANKS_LABEL: 'thanks_label',
  FEEDBACK_SUBMITTED_LABEL: 'feedbackSubmitted_label',
  FEEDBACK_SUBMISSION_FAILURE_HEADING: 'feedbackSubmissionFailure_heading',
  RETRY_SUBMISSION_LABEL: 'retrySubmission_label',
  TRY_AGAIN_LABEL: 'tryAgain_label',
  OTHER_LABEL: 'other_label',
  SUPPLIER_LABEL: 'supplier_label',
  SUPPLIER_DESCRIPTION: 'supplier_description',
  AWS_CUSTOMER_LABEL: 'awsCustomer_label',
  AWS_CUSTOMER_DESCRIPTION: 'awsCustomer_description',
  VENDOR_LABEL: 'vendor_label',
  VENDOR_DESCRIPTION: 'vendor_description',
  SELLING_PARTNER_LABEL: 'sellingPartner_label',
  SELLING_PARTNER_DESCRIPTION: 'sellingPartner_description',
  TCP_SIGNATORY_LABEL: 'tcpSignatory_label',
  TCP_SIGNATORY_DESCRIPTION: 'tcpSignatory_description',
  AMAZON_CUSTOMER_LABEL: 'amazonCustomer_label',
  AMAZON_CUSTOMER_DESCRIPTION: 'amazonCustomer_description',
  MEASUREMENT_LABEL: 'measurement_label',
  CARBON_FREE_ENERGY_LABEL: 'carbonFreeEnergy_label',
  WASTE_AND_CIRCULARITY_LABEL: 'wasteAndCircularity_label',
  GETTING_STARTED_LABEL: 'gettingStarted_label',
  WATER_STEWARDSHIP_LABEL: 'waterStewardship_label',
  BUILDINGS_LABEL: 'buildings_label',
  CARBON_NEUTRALIZATION_LABEL: 'carbonNeutralization_label',
  HUMAN_RIGHTS_LABEL: 'humanRights_label',
  THE_SCIENCE_EXCHANGE_LABEL: 'theScienceExchange_label',
  TRANSPORTATION_LABEL: 'transportation_label',
  GENERAL_CONTENT_INTAKE_LABEL: 'generalContentIntake_label',
  GENERAL_FEEDBACK_LABEL: 'generalFeedback_label',
  TOOL_LABEL: 'tool_label',
  RESOURCE_LABEL: 'resource_label',
  CASE_STUDY_LABEL: 'caseStudy_label',
  FORM_TYPE_LABEL: 'formType_label',
  INPUT_TOO_LONG_LABEL: 'inputTooLong_label',
  JOB_TITLE_LABEL: 'jobTitle_label',
  COMPANY_LABEL: 'company_label',
  WEBSITE_LABEL: 'website_label',
  COMPANY_SIZE_LABEL: 'companySize_label',
  AMAZON_RELATION_LABEL: 'amazonRelation_label',
  ADDITIONAL_RELATION_LABEL: 'additionalRelation_label',
  ADDITIONAL_TOPIC_LABEL: 'additionalTopic_label',
  CONTENT_TYPE_LABEL: 'contentType_label',
  ADDITIONAL_CONTENT_TYPE_LABEL: 'additionalContentType_label',
  INVALID_EMAIL_LABEL: 'invalidEmail_label',
  CONTENT_HEADER: 'content_header',
  CONTENT_DESCRIPTION_LABEL: 'contentDescription_label',
  CONTENT_DESCRIPTION_PLACEHOLDER: 'contentDescription_placeholder',
  FEEDBACK_HEADER: 'feedback_header',
  FEEDBACK_DESCRIPTION_LABEL: 'feedbackDescription_label',
  FEEDBACK_DESCRIPTION_PLACEHOLDER: 'feedbackDescription_placeholder',
  CONTENT_TOPIC_LABEL: 'contentTopic_label',
  FEEDBACK_TOPIC_LABEL: 'feedbackTopic_label',
  ERRORS_PRESENT_DESCRIPTION: 'errorsPresent_description',
  MISSION_HEADER: 'mission_header',
  SELECT_INFO: 'select_info',
  DESCRIPTION_INFO: 'description_info',
  PERSONAL_INFORMATION_HEADER: 'personalInformation_header',
  REQUIRED_FIELDS_LABEL: 'requiredFields_label',
  YES_SELECTOR_OPTION: 'yesSelectorOption_label',
  NO_SELECTOR_OPTION: 'noSelectorOption_label',
  IN_PROCESS_SELECTOR_OPTION: 'inProcessSelectorOption_label',
  SELECT_OPTION: 'selectOption_label',
  IS_MANDATORY: 'isMandatory_label',
  INVALID_WEBSITE_LABEL: 'invalidWebsite_label',
  ERROR_YEAR_DATE: 'errorYearDate_label',
  ERROR_MONTH_YEAR_DATE: 'errorMonthYearDate_label',
  QUESTION_1_ERROR: 'question1Error_label',
  SUBQUESTION_1_ERROR: 'subquestion1Error_label',
  QUESTION_2_ERROR: 'question2Error_label',
  QUESTION_3_ERROR: 'question3Error_label',
  SUBQUESTION_3_ERROR: 'subquestion3Error_label',
  CREDIT_FORM_TITLE: 'creditFormTitle_label',
  CREDIT_FORM_COPY_ONE: 'creditFormCopy1_label',
  CREDIT_FORM_COPY_TWO: 'creditFormCopy2_label',
  CREDIT_FORM_COPY_THREE: 'creditFormCopy3_label',
  CREDIT_FORM_BACK_BUTTON: 'creditFormBackButton_label',
};

// TODO move ID to coral model for reuse in scripts
export const CreditsInterestFormQuestionInfo: { [name: string]: { id: string; text: string } } = {
  QUESTION_1: {
    id: 'NetZeroTargetsThreeScopesQuestion',
    text: 'creditFormQuestion1_label',
  },
  SUBQUESTION_1: {
    id: 'NetZeroTargetYearQuestion',
    text: 'creditFormSubquestion1_label',
  },
  QUESTION_2: {
    id: 'MeasureReportGreenhouseGasEmissionsQuestion',
    text: 'creditFormQuestion2_label',
  },
  QUESTION_3: {
    id: 'ParisAgreementDecarbonizationStrategiesQuestion',
    text: 'creditFormQuestion3_label',
  },
  SUBQUESTION_3: {
    id: 'TargetCompletionDateQuestion',
    text: 'creditFormSubquestion3_label',
  },
};

export const SAVE_FORM_ERROR_LABEL = 'saveFormError_label';

// Contact Form Page Query Parameters
export const FORM_OVERRIDES_QUERY_PARAM = 'formOverrides';
export const METADATA_QUERY_PARAM = 'metadata';

export const PAGE_INGRESS_BY_ROUTE: Record<string, PageIngress> = {
  [ROUTES.ROOT]: PageIngress.HOME_PAGE,
  [ROUTES.SEARCH]: PageIngress.SEARCH,
  [ROUTES.GETTING_STARTED]: PageIngress.GETTING_STARTED,
  [ROUTES.SCIENCE_EXCHANGE]: PageIngress.SCIENCE_EXCHANGE,
  [ROUTES.DATA_INITIATIVE]: PageIngress.DATA_INITIATIVE,
  [ROUTES.MEASUREMENT]: PageIngress.MEASUREMENT,
  [`${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL.Buildings}`]: PageIngress.BUILDINGS,
  [`${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL.CarbonFreeEnergy}`]: PageIngress.CARBON_FREE_ENERGY,
  [`${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL.CarbonNeutralization}`]: PageIngress.CARBON_NEUTRALIZATION,
  [`${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL.HumanRights}`]: PageIngress.HUMAN_RIGHTS,
  [`${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL.Transportation}`]: PageIngress.TRANSPORTATION,
  [`${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL.WasteAndCircularity}`]: PageIngress.WASTE_AND_CIRCULARITY,
  [`${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL.WaterStewardship}`]: PageIngress.WATER_STEWARDSHIP,
};
