import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import { KatModal } from '@amzn/katal-react';
import variables from '@amzn/sss-website-theme/dist/theme/variables.module.scss';

import { AsxImage, FormattedLink, Icon } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './TooltipModalWrapper.module.scss';
import { useTooltipModalWrapperRef } from './TooltipModalWrapper.ref';

export interface TooltipModalProps extends PropsWithChildren {
  /*
   * SECTION_THEME - color theme to be used by the tooltip
   */
  theme?: SECTION_THEME;

  /*
   * Boolean - true to display the modal and false to hide it
   */
  isVisible: boolean;

  /*
   * Object (Optional) - image to be displayed within the tooltip modal
   */
  image?: ImageAttributes;

  /*
   * Object - cta link to redirect the user on click
   */
  cta?: {
    /*
     * String - cta label that will be displayed at the bottom of the card
     */
    label: string;

    /*
     * String - url to be redirected on click
     */
    url: string;
  };

  /*
   * String - body of the tooltip modal
   */
  description: string;

  /*
   * Void - callback triggered on modal close
   */
  close: () => void;
}

export const TooltipModalWrapper: FunctionComponent<TooltipModalProps> = ({
  theme = SECTION_THEME.Light,
  isVisible,
  cta,
  description,
  image,
  close,
}) => {
  const { isSmallDevice, isSmallDesktop } = useDeviceSize();
  const { width } = useDeviceTracker();
  const isMediumDevice = useMediaQuery({ query: `(max-width: ${variables.mediumDevice})` });
  const { modal, modalRef } = useTooltipModalWrapperRef();

  useEffect(() => {
    const modalShadowRoot = modal?.shadowRoot;

    const dialog = modalShadowRoot?.querySelector('.dialog') as HTMLElement | null;

    if (dialog) {
      dialog.style.height = 'unset';
    }

    if (isVisible && modalShadowRoot) {
      const modalBody: HTMLDivElement | null = modalShadowRoot.querySelector('.body');
      const closeButton = modalShadowRoot.querySelector('button') as HTMLButtonElement;

      if (modalBody && closeButton) {
        // Make the modalBody focusable so is keyboard-scrollable
        modalBody.setAttribute('tabindex', '0');
        closeButton.focus();

        // Prevent background scrolling
        document.body.style.overflow = 'hidden';

        // Focus management
        const focusableElements = [closeButton, modalBody] as Array<HTMLElement>;
        let currentFocusIndex = 0;

        const handleTabKey = (event: KeyboardEvent) => {
          if (event.key === 'Tab') {
            event.preventDefault();

            if (event.shiftKey) {
              // Move focus backward (Shift + Tab)
              currentFocusIndex = (currentFocusIndex - 1 + focusableElements.length) % focusableElements.length;
            } else {
              // Move focus forward (Tab)
              currentFocusIndex = (currentFocusIndex + 1) % focusableElements.length;
            }

            // Set focus to the new element
            focusableElements[currentFocusIndex].focus();
          }
        };

        document.addEventListener('keydown', handleTabKey);

        return () => {
          // Cleanup when modal is closed
          document.body.style.overflow = '';
          modalBody.setAttribute('tabindex', '-1');

          document.removeEventListener('keydown', handleTabKey);
        };
      }
    }

    // Explicitly return undefined if conditions are not met
    return undefined;
  }, [isVisible]);

  useEffect(() => {
    const modalShadowRoot = modal?.shadowRoot;
    const dialog = modalShadowRoot?.querySelector('.dialog') as HTMLElement | null;

    // Overriding katal CSS styles to properly position the dialog modal
    if (modalShadowRoot) {
      const modalContainer: HTMLDivElement | null = modalShadowRoot.querySelector('.container');

      const tooltipRightLocated = 'calc(-100% + 32px)';
      const tooltipLeftLocated = '-32px';

      if (modalContainer) {
        if (isSmallDesktop) {
          modalContainer.style.backdropFilter = 'unset';
          modalContainer.style.padding = 'unset';
          modalContainer.style.height = 'fit-content';
          modalContainer.style.position = 'absolute';
          modalContainer.style.top = '24px';
          modalContainer.style.left = '0';
          modalContainer.style.minWidth = '360px';
        } else {
          modalContainer.style.backdropFilter = 'blur(12px)';
          modalContainer.style.padding = '8px';
          modalContainer.style.removeProperty('height');
          modalContainer.style.removeProperty('position');
          modalContainer.style.removeProperty('top');
        }
      }

      if (dialog) {
        if (isSmallDesktop) {
          dialog.style.left = tooltipRightLocated;
          dialog.style.position = 'absolute';
          dialog.style.top = '8px';
          dialog.style.width = '100%';

          const EXTRA_PADDING = 16;
          const { left: dialogLeft } = dialog.getBoundingClientRect();

          dialog.style.left = dialogLeft >= EXTRA_PADDING ? tooltipRightLocated : tooltipLeftLocated;
        } else {
          dialog.style.removeProperty('left');
          dialog.style.removeProperty('position');
          dialog.style.removeProperty('top');
          dialog.style.removeProperty('width');
        }
      }
    }

    // Explicitly return undefined if no cleanup is required
    return undefined;
  }, [isMediumDevice, isSmallDesktop, isVisible, width]);

  useEffect(() => {
    const modalShadowRoot = modal?.shadowRoot;

    const dialog = modalShadowRoot?.querySelector('.dialog') as HTMLElement | null;

    if (!dialog) return undefined;
    dialog.style.height = 'unset';
    dialog.style.width = 'unset';

    // Explicitly return undefined if no cleanup is required
    return undefined;
  }, [isSmallDevice]);

  useEffect(() => {
    if (modal) {
      const titleElement = document.getElementById('modal-title');

      if (titleElement) {
        // Use a minimal timeout to ensure the content is ready
        const timeoutId = setTimeout(() => {
          modal.setAttribute('aria-labelledby', titleElement.innerText);
        }, 0);

        return () => clearTimeout(timeoutId); // Clean up to avoid memory leaks
      }
    }
    // Explicitly return undefined if no cleanup is required
    return undefined;
  }, [isVisible]);

  useEffect(() => {
    if (!modal) return undefined;

    // Explicitly return undefined if no cleanup is required
    return undefined;
  }, [isVisible, isSmallDesktop]);

  return (
    <KatModal
      className={classNames(styles.TooltipModal, styles[theme])}
      visible={isVisible}
      onClose={close}
      noCloseIcon
      footer={false}
      data-testid={isVisible ? 'tooltip-modal-visible' : 'tooltip-modal-invisible'}
      ref={modalRef}
    >
      <div className={classNames(styles.content, styles[theme])}>
        <div className={styles.header}>
          {!isSmallDesktop && <Icon className={styles.infoIcon} name="info" />}

          <button className={styles.iconButton} type="button" onClick={close} data-testid="tooltip-close-cta">
            <Icon className={styles.closeIcon} name="close" />
          </button>
        </div>

        <div className={styles.bodyWrapper}>
          {isSmallDesktop && image && <AsxImage className={styles.thumbnail} {...image} />}
          <div>
            <p className={styles.body} id="modal-title">
              {description}
            </p>
            {cta && <FormattedLink text={cta.label} bold isInternal link={cta.url} iconName="chevronRight" />}
          </div>
        </div>
      </div>
    </KatModal>
  );
};
