import classNames from 'classnames';
import { useEffect, useState, ReactElement, useRef } from 'react';

import variables from '@amzn/sss-website-theme/dist/theme/index.module.scss';

import styles from './Quote.module.scss';

interface QuoteProps {
  /**
   * The string of text to be animated and displayed character by character.
   */
  copy: string;

  /**
   * Optional additional class names to apply to the component for styling purposes.
   */
  className?: string;

  /**
   * Boolean flag used for testing to control whether the component is considered "in view."
   * When provided, it bypasses the IntersectionObserver logic and sets the in-view state directly.
   */
  testIsInView?: boolean; // this prop is for testing only!!
}

export function Quote({ copy, className, testIsInView }: QuoteProps): ReactElement {
  const [progress, setProgress] = useState(0);
  const [isInView, setIsInView] = useState(testIsInView ?? false);

  const quoteRef = useRef<HTMLParagraphElement>(null);

  // istanbul ignore next
  useEffect(() => {
    if (testIsInView !== undefined) {
      setIsInView(testIsInView);
      return; // Skip IntersectionObserver logic for testing
    }

    if (!quoteRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        }
      },
      { threshold: 0.1 }
    );

    observer.observe(quoteRef.current);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, [testIsInView]);

  useEffect(() => {
    if (!isInView) return;

    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= copy.length) {
          clearInterval(interval);
          return prev;
        }
        return prev + 1;
      });
    }, 25);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [isInView, copy]);

  return (
    <p ref={quoteRef} className={classNames(styles.quote, className)}>
      <span className={variables.visuallyHidden} data-testid="hidden-copy">
        {copy}
      </span>
      {copy.split('').map((character, index) => (
        <span
          key={index}
          style={{
            color: index < progress ? variables.greenSecondary : `rgba(242, 247, 247, 0.2)`,
          }}
          aria-hidden
        >
          {character}
        </span>
      ))}
    </p>
  );
}
