import classNames from 'classnames';
import { type ReactElement } from 'react';

import { AsxImage } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './AccordionComponentCard.module.scss';

type AccordionComponentCardProps = {
  theme?: SECTION_THEME;
  title: string;
  subtitle: string;
  image: ImageAttributes;
};

export function AccordionComponentCard({
  title,
  subtitle,
  image,
  theme = SECTION_THEME.Light,
}: AccordionComponentCardProps): ReactElement {
  return (
    <div className={classNames(styles.AccordionComponentCard, styles[theme])}>
      <div className={styles.titleWrapper}>
        <h3 className={styles.title}>{title}</h3>
        <AsxImage key={`${image.src}-${image.alt}`} className={styles.image} {...image} />
      </div>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
  );
}
