import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import variables from '@amzn/sss-website-theme/dist/theme/variables.module.scss';

import { PrimaryButton } from 'src/components/atoms';
import { ImagesBackground } from 'src/components/common/images-background/ImagesBackground';
import { RedirectionCardProps } from 'src/components/common/redirection-card/RedirectionCard';
import { RedirectionCardList } from 'src/components/common/redirection-card-list/RedirectionCardList';
import { FormStrings } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';

import { useThankYouData } from './useThankYouData';

interface ThankYouPageProps {
  submissionStatus: 'success' | 'failure';
}

export const ThankYouPage: FunctionComponent<ThankYouPageProps> = ({ submissionStatus }) => {
  const navigate = useNavigate();
  const { contactFormBundle, images, subtitle } = useThankYouData();
  const cards: RedirectionCardProps[] = [];
  const isSuccess = submissionStatus === 'success';

  const ThankYouContainer = styled.div`
    display: flex !important;
    align-items: center;
    margin-top: 9.3vh;
    flex-direction: column;
    height: 100vh;
    color: ${variables.greenPrimary};
    background-color: ${variables.greySecondary};
    padding: 0 16px;

    @media (min-width: ${variables.largeDevice}) {
      margin-top: 0;
      justify-content: center;
    }

    @media (min-width: ${variables.smallDesktop}) {
      padding: 0 60px;
    }
  `;

  const CopyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 72px;
  `;

  const ThankYouContent = styled.div`
    text-align: center;
    width: 80%;

    @media (min-width: ${variables.smallDesktop}) {
      width: 60%;
      flex-basis: 60%;
    }
  `;

  const Title = styled.h2`
    margin-top: 32px !important;
    margin-bottom: 48px !important;
  `;

  const BackCta = styled(PrimaryButton)`
    font-size: 16px !important;
  `;

  const handleCtaClick = () => {
    if (isSuccess) {
      // navigate to previous page in browser history
      navigate(-1);
    } else {
      // refresh the current route (contact form) in browser history
      navigate(0);
    }
  };

  const ctaLabel = isSuccess ? t(contactFormBundle, 'previousPage_label') : t(contactFormBundle, 'tryAgain_label');

  return (
    <ThankYouContainer>
      <CopyContainer>
        <ThankYouContent>
          {isSuccess ? (
            <>
              <p>{t(contactFormBundle, FormStrings.FEEDBACK_SUBMITTED_LABEL)}</p>
              <Title>{t(contactFormBundle, FormStrings.THANKS_LABEL)}</Title>
            </>
          ) : (
            <>
              <Title>{t(contactFormBundle, FormStrings.FEEDBACK_SUBMISSION_FAILURE_HEADING)}</Title>
              <p>{t(contactFormBundle, FormStrings.RETRY_SUBMISSION_LABEL)}</p>
            </>
          )}
        </ThankYouContent>

        <BackCta
          onClick={handleCtaClick}
          label={ctaLabel}
          theme={SECTION_THEME.Light}
          icon="chevronRight"
          data-testid="backCta"
        />
      </CopyContainer>

      {cards.length > 0 ? (
        <RedirectionCardList subtitle={subtitle} cards={cards} />
      ) : (
        <ImagesBackground images={images} />
      )}
    </ThankYouContainer>
  );
};
