import classNames from 'classnames';
import { type ReactElement } from 'react';

import { Icon } from 'src/components/atoms';

import styles from './Checkmark.module.scss';

interface CheckmarkProps {
  /**
   * Determines whether the checkmark is checked or not.
   * Defaults to false if not provided.
   */
  checked?: boolean;

  /**
   * If true, renders a round checkmark. Defaults to false, which renders a square checkmark.
   */
  isRound?: boolean;

  /**
   * If true, applies error styling (e.g., for validation errors).
   */
  error?: boolean;

  /**
   * Additional custom CSS class names to be applied to the checkmark container.
   */
  className?: string;
}

export function Checkmark({ checked = false, isRound = false, error, className }: CheckmarkProps): ReactElement {
  return (
    <div className={styles.checkmarkWrapper}>
      {isRound ? (
        <span className={classNames(styles.checkmark, styles.round, error && styles.error, className)}>
          {checked && <Icon name="roundCheck" aria-hidden />}
        </span>
      ) : (
        <span
          className={classNames(
            styles.checkmark,
            styles.square,
            checked && styles.checked,
            error && styles.error,
            className
          )}
        >
          {checked && <Icon className={classNames(styles.icon, checked && styles.checked)} name="check" aria-hidden />}
        </span>
      )}
    </div>
  );
}
