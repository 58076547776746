import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { THEME } from 'src/enums/theme';

import styles from './HeadingLink.module.scss';

export type HeadingLinkVariant = 'primary' | 'secondary';
interface HeadingLinkProps {
  href: string;
  text: string;
  variant?: HeadingLinkVariant;
  theme?: THEME;
}

export const HeadingLink: FunctionComponent<HeadingLinkProps> = ({
  href,
  text,
  variant = 'primary',
  theme = THEME.LIGHT,
}) => {
  const isSecondary = variant === 'secondary';

  return (
    <a
      className={classNames(styles.headingLink, styles[theme], {
        [styles.secondary]: isSecondary,
      })}
      href={href}
    >
      <h2 className={styles.heading}>{text}</h2>
    </a>
  );
};
