import { useMemo } from 'react';

import { MessageBundle } from '@amzn/arb-tools';

import { HMD_RATING_OPTIONS, HMDReasonOptionLabels } from 'src/constants';
import { t } from 'src/helpers';

export type ResponseOption = { value: string; optionLabel: string };

export const useRatingOptions = (rating: HMD_RATING_OPTIONS, hmdBundle: MessageBundle): ResponseOption[] =>
  useMemo(() => {
    const createOption = (label: string) => ({
      value: t(hmdBundle, label),
      optionLabel: t(hmdBundle, label),
    });

    const commonOptions = [createOption(HMDReasonOptionLabels.OTHER)];

    const negativeOptions = [
      createOption(HMDReasonOptionLabels.NOT_APPLICABLE_ROLE),
      createOption(HMDReasonOptionLabels.NOT_APPLICABLE_REGION),
      createOption(HMDReasonOptionLabels.NOT_APPLICABLE_ORG),
      createOption(HMDReasonOptionLabels.NOT_EASY_TO_UNDERSTAND),
      createOption(HMDReasonOptionLabels.NOT_APPROPRIATE_DETAIL),
      createOption(HMDReasonOptionLabels.NOT_INCREASED_KNOWLEDGE),
      ...commonOptions,
    ];

    const positiveOptions = [
      createOption(HMDReasonOptionLabels.APPLICABLE_ROLE),
      createOption(HMDReasonOptionLabels.APPLICABLE_REGION),
      createOption(HMDReasonOptionLabels.APPLICABLE_ORG),
      createOption(HMDReasonOptionLabels.EASY_TO_UNDERSTAND),
      createOption(HMDReasonOptionLabels.APPROPRIATE_DETAIL),
      createOption(HMDReasonOptionLabels.INCREASED_KNOWLEDGE),
      ...commonOptions,
    ];

    switch (rating) {
      case HMD_RATING_OPTIONS.RATING_1:
      case HMD_RATING_OPTIONS.RATING_2:
      case HMD_RATING_OPTIONS.RATING_3:
        return negativeOptions;
      case HMD_RATING_OPTIONS.RATING_4:
      case HMD_RATING_OPTIONS.RATING_5:
        return positiveOptions;
      default:
        return [];
    }
  }, [hmdBundle, rating]);
