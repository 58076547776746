import classNames, { type Argument } from 'classnames';
import { type ReactElement } from 'react';
import React from 'react';

import { IconType, PrimaryButton, FormEntryPointCard, FormattedLink } from 'src/components/atoms';
import { SUBTITLE_CLASS } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';

import styles from './TextComponent.module.scss';

interface TextComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  copy?: string;
  externalLink?: string;
  heading: string;
  ctas?: {
    primary?: {
      copy: string;
      href: string;
      icon?: string;
    };
    secondary?: {
      copy: string;
      href: string;
    };
    tertiary?: {
      href: string;
    };
  };
  theme?: SECTION_THEME;
  headingSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  contentClassName?: Argument;
  headingClassName?: Argument;
  copyClassName?: Argument;
  formVariant?: 'stories' | 'resources';
  /* Optional number parameter that indicates how many words should be highlighted starting from the end of the string, 
  for example a 2 indicates that the last 2 words from the string should be highlighted */
  highlightWordsCount?: number;
  titleAlignment?: 'right' | 'left';
}

export function TextComponent({
  className,
  copy,
  externalLink,
  heading,
  ctas,
  theme = SECTION_THEME.Dark,
  headingSize = 'h2',
  headingClassName,
  contentClassName,
  copyClassName,
  formVariant = 'stories',
  highlightWordsCount,
  titleAlignment = 'left',
  ...props
}: TextComponentProps): ReactElement {
  const HeadingTag = React.createElement(
    headingSize,
    { className: classNames(styles.heading, headingClassName, titleAlignment === 'right' && styles.alignRight) },
    highlightWordsCount
      ? (() => {
          const words = heading.split(' ');
          const highlightStart = words.slice(-highlightWordsCount).join(' ');
          const normalStart = words.slice(0, -highlightWordsCount).join(' ');
          return (
            <>
              {normalStart && <>{normalStart} </>}
              <span className={styles.highlightedText}>{highlightStart}</span>
            </>
          );
        })()
      : heading
  );

  const DescriptionContent = copy && (
    <div
      className={classNames(styles.description, SUBTITLE_CLASS, copyClassName)}
      data-testid="textComponentDescription"
    >
      {copy
        .split('*')
        .map((part, index) =>
          index % 2 === 0
            ? part
            : externalLink && (
                <FormattedLink
                  key={part}
                  className={styles.externalLink}
                  showIcon
                  variant="outlined"
                  text={part}
                  link={externalLink}
                  openNewTab
                />
              )
        )}
    </div>
  );

  return (
    <div
      className={classNames(styles.TextComponent, styles[theme], className, ctas?.tertiary && styles.entryPoint)}
      {...(props as React.HTMLAttributes<HTMLDivElement>)}
    >
      {ctas?.tertiary ? (
        <>
          <div className={classNames(styles.textWrapper, styles[theme], styles.contactUsWrapper)}>
            {HeadingTag}
            <div className={classNames(styles.content, contentClassName)}>
              {DescriptionContent}

              <div className={styles.ctasContainer}>
                {ctas?.primary && (
                  <PrimaryButton
                    icon={(ctas.primary.icon as IconType) ?? 'chevronRight'}
                    label={ctas.primary.copy}
                    link={ctas.primary.href}
                    theme={theme}
                    isBold
                    className={styles.primaryCta}
                  />
                )}

                {ctas?.secondary && (
                  <FormattedLink
                    className={styles.secondaryCta}
                    showIcon
                    text={ctas.secondary.copy}
                    link={ctas.secondary.href}
                    openNewTab
                  />
                )}
              </div>
            </div>
          </div>
          <FormEntryPointCard theme={theme} formUrl={ctas.tertiary.href} variant={formVariant} />
        </>
      ) : (
        <>
          {HeadingTag}
          <div className={classNames(styles.content, contentClassName)}>
            {DescriptionContent}

            <div className={styles.ctasContainer}>
              {ctas?.primary && (
                <PrimaryButton
                  icon={(ctas.primary.icon as IconType) ?? 'chevronRight'}
                  label={ctas.primary.copy}
                  link={ctas.primary.href}
                  theme={theme}
                  isBold
                  className={styles.primaryCta}
                />
              )}

              {ctas?.secondary && (
                <FormattedLink
                  className={styles.secondaryCta}
                  showIcon
                  text={ctas.secondary.copy}
                  link={ctas.secondary.href}
                  openNewTab
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
