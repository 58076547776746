import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { THEME } from 'src/enums/theme';
import SearchIcon from 'src/icons/search.svg';

import styles from './SearchButtonLink.module.scss';

interface SearchButtonLinkProps {
  href: string;
  text: string;
  styleVariant?: 'primary' | 'secondary';
  theme?: THEME;
}

export const SearchButtonLink: FunctionComponent<SearchButtonLinkProps> = ({
  href,
  styleVariant = 'primary',
  text,
  theme = THEME.LIGHT,
}) => {
  const isSecondary = styleVariant === 'secondary';

  return (
    <a
      aria-label={text}
      className={classNames(styles.searchLink, styles[theme], { [styles.linkSecondary]: isSecondary })}
      href={href}
    >
      <SearchIcon
        className={classNames(styles.searchIcon, styles[theme], { [styles.iconSecondary]: isSecondary })}
        role="img"
      />
    </a>
  );
};
