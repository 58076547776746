import classNames from 'classnames';
import { HTMLAttributes, useCallback, useEffect, useRef, useState, type FunctionComponent } from 'react';

import { IconType } from 'src/components/atoms/icons/icons';
import { DynamicItem } from 'src/components/common/dynamic-list/dynamic-item/DynamicItem';
import { SUBTITLE_CLASS } from 'src/constants';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';

import styles from './DynamicList.module.scss';
import { DesktopHeader } from './headers/DesktopHeader';
import { MobileHeader } from './headers/MobileHeader';

export type DynamicListItem = {
  body: string;
  title: string;
  tagline?: string;
  link?: { text: string; href?: string; icon?: IconType; onClickOverride?: () => void };
};

interface DynamicListProps extends HTMLAttributes<HTMLDivElement> {
  heading: string;
  subtitle: string;
  items: Array<DynamicListItem>;
  SVGAriaLabel: string;
  isModalVisible?: boolean;
  focusIndex?: number;
}

export const DynamicList: FunctionComponent<DynamicListProps> = ({
  className,
  heading,
  subtitle,
  items,
  SVGAriaLabel,
  isModalVisible,
  focusIndex,
  ...props
}) => {
  const { isDesktop } = useDeviceTracker();

  const self = useRef<HTMLDivElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const [activeSlide, setActiveSlide] = useState(0);

  const getContentHeight = (element: HTMLElement) => {
    const heightWithPaddings = element.clientHeight;
    const elementComputedStyle = window.getComputedStyle(element, null);

    return (
      heightWithPaddings - parseFloat(elementComputedStyle.paddingTop) - parseFloat(elementComputedStyle.paddingBottom)
    );
  };

  const handleResize = useCallback(() => {
    if (!self.current || !imageContainerRef.current || !listRef.current) return;

    const lastItem = listRef.current.lastChild as HTMLElement;
    const imageHeight = getContentHeight(imageContainerRef.current);

    const additionalBottomPaddingPx = imageHeight - lastItem.offsetHeight;
    listRef.current.style.paddingBottom = `${additionalBottomPaddingPx}px`;

    if (!isDesktop) {
      self.current.style.removeProperty('padding-bottom');
      self.current.style.removeProperty('top');
      return;
    }

    imageContainerRef.current.style.top = `10%`;

    const distanceToBottomPx = (window.innerHeight - imageContainerRef.current.offsetHeight) / 2;
    self.current.style.paddingBottom = `${distanceToBottomPx}px`;
  }, [isDesktop]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [self, handleResize]);

  useEffect(() => {
    (self.current?.querySelector(`[data-cta-index='${focusIndex}']`) as HTMLElement)?.focus();
  }, [focusIndex]);

  return (
    <>
      <div ref={self} className={classNames(className, styles.dynamicList)} id={styles.dynamicList} {...props}>
        {isDesktop ? (
          <DesktopHeader heading={heading} activeSlide={activeSlide} />
        ) : (
          <MobileHeader heading={heading} subtitle={subtitle} activeSlide={activeSlide} items={items} />
        )}

        <div className={styles.list}>
          {isDesktop && <p className={classNames(styles.subtitle, SUBTITLE_CLASS)}>{subtitle}</p>}
          <ul ref={listRef}>
            {isDesktop ? (
              <>
                {items.map((item, index) => (
                  <DynamicItem
                    key={item.title}
                    {...item}
                    isFirst={index === 0}
                    isLast={index === items.length - 1}
                    onActiveChange={() => setActiveSlide(index)}
                    isActive={index === activeSlide}
                    isModalVisible={isModalVisible}
                    index={index}
                  />
                ))}
              </>
            ) : (
              <>
                {items.map(
                  (item, index) =>
                    index !== items.length - 1 && (
                      <DynamicItem
                        key={item.title}
                        {...item}
                        isFirst={index === 0}
                        isLast
                        onActiveChange={() => setActiveSlide(index)}
                        isActive={index === activeSlide}
                        isModalVisible={isModalVisible}
                        index={index}
                      />
                    )
                )}
              </>
            )}
          </ul>
        </div>
      </div>

      {!isDesktop && (
        <ul>
          {items.map(
            (item, index) =>
              index === items.length - 1 && (
                <DynamicItem
                  key={item.title}
                  {...item}
                  isFirst={index === 0}
                  isLast
                  onActiveChange={() => setActiveSlide(index)}
                  isActive={index === activeSlide}
                  isModalVisible={isModalVisible}
                  index={index}
                />
              )
          )}
        </ul>
      )}
    </>
  );
};
