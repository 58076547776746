import { FunctionComponent, useEffect, useRef } from 'react';
import { Controller, useForm, FieldError } from 'react-hook-form';

import { MessageBundle } from '@amzn/arb-tools';
import { RelationToAmazon } from '@amzn/coral_com-amazon-sssapinterface-model-structures-form';
import { useBundle } from '@amzn/react-arb-tools';

import { Icon, PRIMARY_BUTTON_SIZE, PrimaryButton, AsxMarkdownContent } from 'src/components/atoms';
import { GridImage } from 'src/components/common';
import { DropdownInput, SelectGroup, DropdownInputMultiSelection, TextInput } from 'src/components/form-elements';
import {
  emailRegex,
  relationToAmazonDescriptionTranslationMap,
  relationToAmazonTranslationMap,
  shortInputMaxLength,
  monthYearRegex,
  yearRegex,
} from 'src/components/pages/contact/form-helpers';
import { COMMON_BUNDLE_NAME, FormStrings, CreditsInterestFormQuestionInfo } from 'src/constants';
import { addRequiredLabelSymbol, t } from 'src/helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import generalFormImage from 'src/images/fallback/fallback-image-3.png';
import contentFormImage from 'src/images/list-component/listComponent01.jpeg';

import styles from './CreditsInterestForm.module.scss';

export interface CreditsInterestFormProps {
  creditFormBundle: MessageBundle;
  handleFormSubmit: (formValues: CreditsInterestFormValues) => void;
}

export enum CREDITS_INTEREST_FORM_KEYS {
  NAME = 'name',
  EMAIL = 'email',
  WEBSITE = 'website',
  JOB_TITLE = 'jobTitle',
  COMPANY = 'company',
  COMPANY_SIZE = 'companySize',
  RELATIONS_TO_AMAZON = 'relationsToAmazon',
  QUESTION_1 = 'question1',
  SUBQUESTION_1 = 'subquestion1',
  QUESTION_2 = 'question2',
  QUESTION_3 = 'question3',
  SUBQUESTION_3 = 'subquestion3',
}

export type CreditsInterestFormValues = {
  [CREDITS_INTEREST_FORM_KEYS.NAME]: string;
  [CREDITS_INTEREST_FORM_KEYS.EMAIL]: string;
  [CREDITS_INTEREST_FORM_KEYS.WEBSITE]: string;
  [CREDITS_INTEREST_FORM_KEYS.JOB_TITLE]: string;
  [CREDITS_INTEREST_FORM_KEYS.COMPANY]: string;
  [CREDITS_INTEREST_FORM_KEYS.COMPANY_SIZE]: string;
  [CREDITS_INTEREST_FORM_KEYS.RELATIONS_TO_AMAZON]: RelationToAmazon[];
  [CREDITS_INTEREST_FORM_KEYS.QUESTION_1]: string;
  [CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_1]: string;
  [CREDITS_INTEREST_FORM_KEYS.QUESTION_2]: string;
  [CREDITS_INTEREST_FORM_KEYS.QUESTION_3]: string;
  [CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_3]: string;
};

export const CreditsInterestForm: FunctionComponent<CreditsInterestFormProps> = ({
  creditFormBundle,
  handleFormSubmit,
}) => {
  const {
    register,
    unregister,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    control,
    watch,
    formState: { errors },
  } = useForm<CreditsInterestFormValues>({
    reValidateMode: 'onSubmit',
  });

  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  const errorsPresentRef = useRef<HTMLDivElement>(null);
  const formHasErrors = Object.keys(errors).length > 0;

  const { isSmallDesktop } = useDeviceSize();

  const isMandatoryCopy = t(creditFormBundle, FormStrings.IS_MANDATORY);
  const tooLongInputCopy = `${t(creditFormBundle, FormStrings.INPUT_TOO_LONG_LABEL)} ${shortInputMaxLength}`;

  enum ANSWERS {
    yes = 'yes',
    no = 'no',
    inProcess = 'in process',
  }

  const questionOneandTwo = [
    { value: ANSWERS.yes, optionLabel: t(creditFormBundle, FormStrings.YES_SELECTOR_OPTION) },
    { value: ANSWERS.no, optionLabel: t(creditFormBundle, FormStrings.NO_SELECTOR_OPTION) },
  ];

  const questionThree = [
    { value: ANSWERS.yes, optionLabel: t(creditFormBundle, FormStrings.YES_SELECTOR_OPTION) },
    { value: ANSWERS.no, optionLabel: t(creditFormBundle, FormStrings.NO_SELECTOR_OPTION) },
    { value: ANSWERS.inProcess, optionLabel: t(creditFormBundle, FormStrings.IN_PROCESS_SELECTOR_OPTION) },
  ];

  const companySizeRanges = [
    { value: '0-100', label: `0 - 100 ${t(commonBundle, 'picker_employees')}` },
    { value: '101-1000', label: `101 - 1000 ${t(commonBundle, 'picker_employees')}` },
    { value: '1001-2000', label: `1,001 - 5,000 ${t(commonBundle, 'picker_employees')}` },
    { value: '5001-10000', label: `5,001 - 10,000 ${t(commonBundle, 'picker_employees')}` },
    { value: '10001+', label: `10,001 + ${t(commonBundle, 'picker_employees')}` },
  ];

  useEffect(() => {
    if (formHasErrors && errorsPresentRef.current) {
      // We want to offset our scroll by 120 on desktop and 96 on mobile to avoid our errors present element being
      // hidden behind the navbar
      const navbarOffset = isSmallDesktop ? 120 : 96;
      const topOffset = errorsPresentRef.current.getBoundingClientRect().top + window.scrollY - navbarOffset;
      window.scrollTo({ behavior: 'smooth', top: topOffset });
    }
  }, [formHasErrors, isSmallDesktop]);

  const watchQuestionOneResponse = watch(CREDITS_INTEREST_FORM_KEYS.QUESTION_1)?.includes('yes');
  const watchQuestionThreeResponse = watch(CREDITS_INTEREST_FORM_KEYS.QUESTION_3)?.includes('in process');

  useEffect(() => {
    // Clear errors when the component mounts
    clearErrors();

    // Unregister additional fields when the 'other' option is deselected
    const handleAdditionalFields = () => {
      if (watchQuestionOneResponse) {
        register(CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_1);
      } else if (!watchQuestionOneResponse) {
        unregister(CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_1);
      }
    };

    // Call the handleAdditionalFields function whenever the watched values change
    handleAdditionalFields();

    // Cleanup function to handle the additional fields when the component unmounts
    return () => handleAdditionalFields();
  }, [clearErrors, watchQuestionOneResponse, register, unregister]);

  return (
    <div className={styles.contactFormWrapper}>
      <div className={styles.contactFormImageContainer} data-testid="formImageContainer">
        <h2 className={styles.contactFormHeading}>{t(creditFormBundle, FormStrings.CREDIT_FORM_TITLE)}</h2>
        <GridImage
          isFormVariant
          image={{
            src: generalFormImage,
            alt: t(creditFormBundle, 'generalFeedbackImage_alt'),
          }}
          smallImage={{
            src: contentFormImage,
            alt: t(creditFormBundle, 'generalFeedbackImage_alt'),
          }}
        />
      </div>
      <form
        /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
        onSubmit={handleSubmit(handleFormSubmit)}
        className={styles.form}
        id="creditInterestForm"
        data-testid="creditInterestForm"
      >
        <div className={styles.formParagraphWrapper}>
          <AsxMarkdownContent
            className={styles.formParagraph}
            copy={t(creditFormBundle, FormStrings.CREDIT_FORM_COPY_ONE)}
          />
          <p className={styles.formParagraph}>{t(creditFormBundle, FormStrings.CREDIT_FORM_COPY_TWO)}</p>
          <p className={styles.formParagraph}>{t(creditFormBundle, FormStrings.CREDIT_FORM_COPY_THREE)}</p>
        </div>

        {formHasErrors && (
          <div className={styles.formErrorsPresentWrapper} ref={errorsPresentRef}>
            <Icon name="error" className={styles.formErrorsPresentIcon} />
            <p className={styles.formErrorsPresent}>{t(creditFormBundle, FormStrings.ERRORS_PRESENT_DESCRIPTION)}</p>
          </div>
        )}
        <div>
          <p className={styles.formRequiredFields}>{t(creditFormBundle, FormStrings.REQUIRED_FIELDS_LABEL)}</p>
        </div>
        <div>
          <h4 className={styles.formSectionHeader}>{t(creditFormBundle, FormStrings.PERSONAL_INFORMATION_HEADER)}</h4>
        </div>
        <div className={styles.formInputWrapper}>
          <TextInput
            label={addRequiredLabelSymbol(t(creditFormBundle, FormStrings.NAME_PLACEHOLDER_LABEL))}
            inputType="text"
            id={CREDITS_INTEREST_FORM_KEYS.NAME}
            value={getValues(CREDITS_INTEREST_FORM_KEYS.NAME) ?? ''}
            {...register(CREDITS_INTEREST_FORM_KEYS.NAME, {
              required: `${t(creditFormBundle, FormStrings.NAME_PLACEHOLDER_LABEL)}${isMandatoryCopy}`,
              maxLength: {
                value: shortInputMaxLength,
                message: tooLongInputCopy,
              },
            })}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const fieldValue = event.target.value;
              setValue(CREDITS_INTEREST_FORM_KEYS.NAME, fieldValue);
              clearErrors(CREDITS_INTEREST_FORM_KEYS.NAME);
            }}
            errors={errors[CREDITS_INTEREST_FORM_KEYS.NAME]}
            iconName="profile"
          />
        </div>
        <div className={styles.formInputWrapper}>
          <TextInput
            label={addRequiredLabelSymbol(t(creditFormBundle, FormStrings.EMAIL_PLACEHOLDER_LABEL))}
            inputType="text"
            id={CREDITS_INTEREST_FORM_KEYS.EMAIL}
            value={getValues(CREDITS_INTEREST_FORM_KEYS.EMAIL) ?? ''}
            {...register(CREDITS_INTEREST_FORM_KEYS.EMAIL, {
              required: `${t(creditFormBundle, FormStrings.EMAIL_PLACEHOLDER_LABEL)}${isMandatoryCopy}`,
              maxLength: {
                value: shortInputMaxLength,
                message: tooLongInputCopy,
              },
              pattern: {
                value: emailRegex,
                message: t(creditFormBundle, FormStrings.INVALID_EMAIL_LABEL),
              },
            })}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const fieldValue = event.target.value;
              setValue(CREDITS_INTEREST_FORM_KEYS.EMAIL, fieldValue);
              clearErrors(CREDITS_INTEREST_FORM_KEYS.EMAIL);
            }}
            errors={errors[CREDITS_INTEREST_FORM_KEYS.EMAIL]}
            iconName="mail"
          />
        </div>
        <div className={styles.formInputWrapper}>
          <TextInput
            label={addRequiredLabelSymbol(t(creditFormBundle, FormStrings.JOB_TITLE_LABEL))}
            inputType="text"
            id={CREDITS_INTEREST_FORM_KEYS.JOB_TITLE}
            value={getValues(CREDITS_INTEREST_FORM_KEYS.JOB_TITLE) ?? ''}
            {...register(CREDITS_INTEREST_FORM_KEYS.JOB_TITLE, {
              required: `${t(creditFormBundle, FormStrings.JOB_TITLE_LABEL)}${isMandatoryCopy}`,
              maxLength: {
                value: shortInputMaxLength,
                message: tooLongInputCopy,
              },
            })}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const fieldValue = event.target.value;
              setValue(CREDITS_INTEREST_FORM_KEYS.JOB_TITLE, fieldValue);
              clearErrors(CREDITS_INTEREST_FORM_KEYS.JOB_TITLE);
            }}
            errors={errors[CREDITS_INTEREST_FORM_KEYS.JOB_TITLE]}
            iconName="job"
          />
        </div>
        <div className={styles.formInputWrapper}>
          <TextInput
            label={addRequiredLabelSymbol(t(creditFormBundle, FormStrings.COMPANY_LABEL))}
            inputType="text"
            id={CREDITS_INTEREST_FORM_KEYS.COMPANY}
            value={getValues(CREDITS_INTEREST_FORM_KEYS.COMPANY) ?? ''}
            {...register(CREDITS_INTEREST_FORM_KEYS.COMPANY, {
              required: `${t(creditFormBundle, FormStrings.COMPANY_LABEL)}${isMandatoryCopy}`,
              maxLength: {
                value: shortInputMaxLength,
                message: tooLongInputCopy,
              },
            })}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const fieldValue = event.target.value;
              setValue(CREDITS_INTEREST_FORM_KEYS.COMPANY, fieldValue);
              clearErrors(CREDITS_INTEREST_FORM_KEYS.COMPANY);
            }}
            errors={errors[CREDITS_INTEREST_FORM_KEYS.COMPANY]}
            iconName="companyProfile"
          />
        </div>
        <div className={styles.formInputWrapper}>
          <TextInput
            label={addRequiredLabelSymbol(t(creditFormBundle, FormStrings.WEBSITE_LABEL))}
            inputType="text"
            id={CREDITS_INTEREST_FORM_KEYS.WEBSITE}
            value={getValues(CREDITS_INTEREST_FORM_KEYS.WEBSITE) ?? ''}
            {...register(CREDITS_INTEREST_FORM_KEYS.WEBSITE, {
              required: `${t(creditFormBundle, FormStrings.WEBSITE_LABEL)}${isMandatoryCopy}`,
              maxLength: {
                value: shortInputMaxLength,
                message: tooLongInputCopy,
              },
            })}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const fieldValue = event.target.value;
              setValue(CREDITS_INTEREST_FORM_KEYS.WEBSITE, fieldValue);
              clearErrors(CREDITS_INTEREST_FORM_KEYS.WEBSITE);
            }}
            errors={errors[CREDITS_INTEREST_FORM_KEYS.WEBSITE]}
            iconName="link"
          />
        </div>
        <div className={styles.formInputWrapperLarge}>
          <DropdownInput
            label={addRequiredLabelSymbol(t(creditFormBundle, FormStrings.COMPANY_SIZE_LABEL))}
            control={control}
            name={CREDITS_INTEREST_FORM_KEYS.COMPANY_SIZE}
            options={companySizeRanges}
            errors={errors[CREDITS_INTEREST_FORM_KEYS.COMPANY_SIZE]}
            required={`${t(creditFormBundle, FormStrings.COMPANY_SIZE_LABEL)}${isMandatoryCopy}`}
            onChange={(value: string) => {
              setValue(CREDITS_INTEREST_FORM_KEYS.COMPANY_SIZE, value);
              clearErrors(CREDITS_INTEREST_FORM_KEYS.COMPANY_SIZE);
            }}
            isRadioVariant
          />
        </div>
        <div className={styles.formInputWrapperLarge}>
          <DropdownInputMultiSelection
            label={addRequiredLabelSymbol(t(creditFormBundle, FormStrings.AMAZON_RELATION_LABEL))}
            control={control}
            options={Object.values(RelationToAmazon).map((relation) => ({
              value: relation,
              label: t(creditFormBundle, relationToAmazonTranslationMap[relation], undefined, relation),
              description:
                relation === RelationToAmazon.OTHER
                  ? ''
                  : t(creditFormBundle, relationToAmazonDescriptionTranslationMap[relation], undefined, relation),
            }))}
            sublabel={t(creditFormBundle, FormStrings.SELECT_INFO)}
            errors={errors[CREDITS_INTEREST_FORM_KEYS.RELATIONS_TO_AMAZON] as FieldError}
            {...register(CREDITS_INTEREST_FORM_KEYS.RELATIONS_TO_AMAZON, {
              required: `${t(creditFormBundle, FormStrings.AMAZON_RELATION_LABEL)}${isMandatoryCopy}`,
            })}
            onChange={(value, isChecked) => {
              const currentSelection: RelationToAmazon[] =
                getValues(CREDITS_INTEREST_FORM_KEYS.RELATIONS_TO_AMAZON) ?? [];

              if (isChecked && value && !currentSelection.includes(value)) {
                setValue(CREDITS_INTEREST_FORM_KEYS.RELATIONS_TO_AMAZON, [...currentSelection, value]);
              } else if (!isChecked && value && currentSelection.includes(value)) {
                setValue(
                  CREDITS_INTEREST_FORM_KEYS.RELATIONS_TO_AMAZON,
                  currentSelection.filter((type) => type !== value)
                );
              }
              clearErrors(CREDITS_INTEREST_FORM_KEYS.RELATIONS_TO_AMAZON);
            }}
          />
        </div>
        <div className={styles.formInputWrapperLarge}>
          <Controller
            name={CREDITS_INTEREST_FORM_KEYS.QUESTION_1}
            control={control}
            rules={{
              required: {
                value: true,
                message: t(creditFormBundle, FormStrings.QUESTION_1_ERROR),
              },
            }}
            render={({ field: { onChange } }) => (
              <SelectGroup
                selectionType="single"
                id={CREDITS_INTEREST_FORM_KEYS.QUESTION_1}
                label={addRequiredLabelSymbol(t(creditFormBundle, CreditsInterestFormQuestionInfo.QUESTION_1.text))}
                options={questionOneandTwo}
                testId={CREDITS_INTEREST_FORM_KEYS.QUESTION_1}
                onChange={(selectedValue, isChecked) => {
                  if (isChecked) {
                    onChange(selectedValue);
                    setValue(CREDITS_INTEREST_FORM_KEYS.QUESTION_1, selectedValue);
                  } else {
                    onChange('');
                    setValue(CREDITS_INTEREST_FORM_KEYS.QUESTION_1, '');
                  }
                  clearErrors(CREDITS_INTEREST_FORM_KEYS.QUESTION_1);
                }}
                errors={errors[CREDITS_INTEREST_FORM_KEYS.QUESTION_1]}
                showAdditionalInput={watchQuestionOneResponse}
                additionalInputProps={{
                  label: addRequiredLabelSymbol(
                    t(creditFormBundle, CreditsInterestFormQuestionInfo.SUBQUESTION_1.text)
                  ),
                  id: CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_1,
                  additionalInputValue: getValues(CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_1) ?? '',
                  iconName: 'calendar',
                  onChange: (nestedInputValue) => {
                    setValue(CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_1, nestedInputValue);
                    clearErrors(CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_1);
                  },
                  register,
                  registerOptions: {
                    required: t(creditFormBundle, FormStrings.SUBQUESTION_1_ERROR),
                    pattern: {
                      value: yearRegex,
                      message: t(creditFormBundle, FormStrings.ERROR_YEAR_DATE),
                    },
                  },
                  error: errors[CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_1],
                }}
              />
            )}
          />
        </div>
        <div className={styles.formInputWrapperLarge}>
          <Controller
            name={CREDITS_INTEREST_FORM_KEYS.QUESTION_2}
            control={control}
            rules={{
              required: {
                value: true,
                message: t(creditFormBundle, FormStrings.QUESTION_2_ERROR),
              },
            }}
            render={({ field: { onChange } }) => (
              <SelectGroup
                selectionType="single"
                id={CREDITS_INTEREST_FORM_KEYS.QUESTION_2}
                label={addRequiredLabelSymbol(t(creditFormBundle, CreditsInterestFormQuestionInfo.QUESTION_2.text))}
                options={questionOneandTwo}
                testId={CREDITS_INTEREST_FORM_KEYS.QUESTION_2}
                onChange={(selectedValue, isChecked) => {
                  if (isChecked) {
                    onChange(selectedValue);
                    setValue(CREDITS_INTEREST_FORM_KEYS.QUESTION_2, selectedValue);
                  } else {
                    onChange('');
                    setValue(CREDITS_INTEREST_FORM_KEYS.QUESTION_2, '');
                  }
                  clearErrors(CREDITS_INTEREST_FORM_KEYS.QUESTION_2);
                }}
                errors={errors[CREDITS_INTEREST_FORM_KEYS.QUESTION_2]}
              />
            )}
          />
        </div>
        <div className={styles.formInputWrapperLarge}>
          <Controller
            name={CREDITS_INTEREST_FORM_KEYS.QUESTION_3}
            control={control}
            rules={{
              required: {
                value: true,
                message: t(creditFormBundle, FormStrings.QUESTION_3_ERROR),
              },
            }}
            render={({ field: { onChange } }) => (
              <SelectGroup
                selectionType="single"
                id={CREDITS_INTEREST_FORM_KEYS.QUESTION_3}
                label={addRequiredLabelSymbol(t(creditFormBundle, CreditsInterestFormQuestionInfo.QUESTION_3.text))}
                options={questionThree}
                testId={CREDITS_INTEREST_FORM_KEYS.QUESTION_3}
                onChange={(selectedValue, isChecked) => {
                  if (isChecked) {
                    onChange(selectedValue);
                    setValue(CREDITS_INTEREST_FORM_KEYS.QUESTION_3, selectedValue);
                  } else {
                    onChange('');
                    setValue(CREDITS_INTEREST_FORM_KEYS.QUESTION_3, '');
                  }
                  clearErrors(CREDITS_INTEREST_FORM_KEYS.QUESTION_3);
                }}
                errors={errors[CREDITS_INTEREST_FORM_KEYS.QUESTION_3]}
                showAdditionalInput={watchQuestionThreeResponse}
                additionalInputProps={{
                  label: addRequiredLabelSymbol(
                    t(creditFormBundle, CreditsInterestFormQuestionInfo.SUBQUESTION_3.text)
                  ),
                  id: CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_3,
                  additionalInputValue: getValues(CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_3) ?? '',
                  iconName: 'calendar',
                  onChange: (nestedInputValue) => {
                    setValue(CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_3, nestedInputValue);
                    clearErrors(CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_3);
                  },
                  register,
                  registerOptions: {
                    required: t(creditFormBundle, FormStrings.SUBQUESTION_3_ERROR),
                    pattern: {
                      value: monthYearRegex,
                      message: t(creditFormBundle, FormStrings.ERROR_MONTH_YEAR_DATE),
                    },
                  },
                  error: errors[CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_3],
                }}
              />
            )}
          />
        </div>
        <div className={styles.submitButtonWrapper} data-testid="submitButtonWrapper">
          <AsxMarkdownContent
            className={styles.privacyNoticeLabel}
            copy={t(creditFormBundle, FormStrings.FORM_SUBMIT_PRIVACY_NOTICE_LABEL_SHORT)}
          />

          <PrimaryButton
            label={t(creditFormBundle, FormStrings.FORM_SUBMIT_BUTTON_LABEL)}
            icon="chevronRight"
            type="submit"
            form="creditInterestForm"
            data-testid="primaryButton"
            buttonSize={PRIMARY_BUTTON_SIZE.Small}
          />
        </div>
      </form>
    </div>
  );
};
