import { useState } from 'react';

import { FormattedLink, Icon, Tagline } from 'src/components/atoms';
import { ModalWrapper } from 'src/components/common';
import { parseTextFragments } from 'src/helpers';

import styles from './HeroOutro.module.scss';

type ExpertInformation = {
  thumbnailSrc: string;
  name: string;
  description: string;
};

export type HeroOutroProps = {
  /* The title in this component is split into 2 fragments, the first one being normal text and the second one having a different
  color and depending on resolution also having a different alignment in relation to the first fragment, we scan for any string wrapped in "*" to split it */
  title: string;
  /* This label is the text between the title and the text, on desktop it is the text seen at the left of the section */
  label: string;
  /* This text is the one used in the link that opens the modal */
  linkText: string;
  /* This textContent is the main content of the outro section, it's the whole string found after the link */
  textContent: string;
  // Modal props
  modalContent: {
    /* This tag is the piece of text that provides context of what the modal is about */
    tag: string;
    /* This is the quote said by the expert which is the main text piece of the modal */
    quote: string;
    expertInformation?: ExpertInformation;
    source: {
      text: string;
      link: string;
    };
  };
};

// This component functions as the last section of the animatedHeroBanner component, it has a modal and
// plain text
export function HeroOutro({ title, label, linkText, textContent, modalContent }: HeroOutroProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { fragment1, fragment2 } = parseTextFragments(title);

  const handleOutroLinkClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div className={styles.bannerOutro} data-testid="animated-hero-banner-outro">
      <div className={styles.titleContainer}>
        <span className={styles.outroTitle}>{fragment1}</span>
        <span className={styles.outroTitleFragment}>{fragment2}</span>
      </div>
      <div className={styles.outroContent}>
        <p className={styles.outroLabel}>{label}</p>
        <h5 className={styles.outroText}>
          <FormattedLink
            className={styles.outroLink}
            showUnderline={false}
            renderAsButton
            text={linkText}
            iconName="plus"
            iconClassName={styles.outroLinkIcon}
            onClick={handleOutroLinkClick}
            data-testid="animated-hero-banner-outro-modal-link"
          />
          {textContent}
        </h5>
      </div>
      <ModalWrapper isVisible={isModalVisible} close={handleModalClose} className={styles.authorModal}>
        <div className={styles.modalContent}>
          <div>
            <Tagline className={styles.modalTag}>{modalContent.tag}</Tagline>
            <h5 className={styles.authorQuote} data-testid="animated-hero-banner-outro-modal-quote">
              {modalContent.quote}
            </h5>
          </div>
          <div>
            {modalContent.expertInformation && (
              <div className={styles.authorInformationContainer}>
                <img
                  className={styles.authorThumbnail}
                  src={modalContent.expertInformation.thumbnailSrc}
                  alt={`${modalContent.expertInformation.name}`}
                />
                <div className={styles.authorInformation}>
                  <p className={styles.authorName}>{modalContent.expertInformation.name}</p>
                  <p className={styles.authorDescription}>{modalContent.expertInformation.description}</p>
                </div>
              </div>
            )}
            <div className={styles.modalFooter}>
              <div className={styles.footerIconWrapper}>
                <Icon name="info" className={styles.footerIcon} />
              </div>
              <FormattedLink
                text={modalContent.source.text}
                showUnderline={false}
                openNewTab
                link={modalContent.source.link}
              />
            </div>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
}
