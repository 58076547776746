// eslint-disable-next-line no-restricted-imports
import { getLibraryDomainRoot } from 'src/helpers/library-helpers';

// Converts a string to sentence case (i.e where just the first letter of the string is capitalized
export const convertToSentenceCase = (str: string) =>
  str.toLowerCase().replace(/^\w/, (char) => char.toLocaleUpperCase());

export const formatUrl = (url?: string) => {
  if (!url) return '';
  // if the string is already a valid url, simply return
  return url.startsWith('http') || url.startsWith('www') ? url : `${getLibraryDomainRoot()}${url}`;
};

export const splitLastWordFromText = (copy: string) => {
  const initialCopyArr = copy.split(' ');
  const lastWord = initialCopyArr.pop();
  const initialCopy = initialCopyArr.join(' ');

  return [initialCopy, lastWord];
};

export const addRequiredLabelSymbol = (inputLabel: string) => `${inputLabel} *`;

export const titleFormatter: (value: string) => string = (value) => value.replace(/_/g, ' ');

export const validateEmptyTextInput = (inputValue: string, outputString: string) => !!inputValue.trim() || outputString;

export const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0');
  const secs = (seconds % 60).toString().padStart(2, '0');
  return `${minutes}:${secs}`;
};

// This helper function will scan a string and split anything that's found inside two "*" characters into a separate fragment,
// (i.e "split this *title*", would leave us with a "split this" fragment and a "title" fragment), we use this when we have a
// string that needs to have a certain part styled differently, like changing the color or alignment of the last words of a title
export const parseTextFragments = (title: string) => {
  const match = title.match(/(.*?)\*(.*?)\*/);
  if (match) {
    return { fragment1: match[1], fragment2: match[2] };
  }
  return { fragment1: title, fragment2: '' }; // Fallback if no match is found
};
