import classNames from 'classnames';
import { ElementRef, FunctionComponent, PropsWithChildren, ReactNode, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';

import { KatModal } from '@amzn/katal-react';
import variables from '@amzn/sss-website-theme/dist/theme/variables.module.scss';

import { useModalAccessibility } from 'src/hooks/useModalAccessibility';

import styles from './ModalWrapper.module.scss';

export enum MODAL_VARIANT {
  info = 'info',
  form = 'form',
}

export interface ModalProps extends PropsWithChildren {
  isVisible: boolean;
  close: () => void;
  title?: string;
  subtitle?: string;
  variant?: MODAL_VARIANT;
  footer?: ReactNode;
  className?: string;
}

/**
 * This component is a wrapper around KatModal. It includes styling and accessibility for the Modal container,
 * and can accept the inner modal content as children.
 * @param isVisible - boolean value indicating if the modal is visible
 * @param close - function that is triggered onClose
 * @param title - string value for the title of the modal
 * @param subtitle - string value for the subtitle of the modal
 * @param children - inner content of the modal
 * @param variant - type of modal container styling that applies
 * @param footer - inner content of the modal footer
 * @param className - class name to be applied to modal
 */
// TODO create storybook stories for new Modal components https://i.amazon.com/issues/ASX-244
export const ModalWrapper: FunctionComponent<ModalProps> = ({
  isVisible,
  close,
  title,
  subtitle,
  children,
  variant = MODAL_VARIANT.info,
  footer,
  className,
}) => {
  const isMediumDevice = useMediaQuery({ query: `(max-width: ${variables.mediumDevice})` });
  const modalRef = useRef<ElementRef<typeof KatModal>>(null);

  useEffect(() => {
    const modal = modalRef.current;
    const modalShadowRoot = modal?.shadowRoot;

    if (modalShadowRoot) {
      const modalContainer: HTMLDivElement | null = modalShadowRoot.querySelector('.container');
      if (modalContainer) {
        modalContainer.style.backdropFilter = 'blur(12px)';
        if (variant === MODAL_VARIANT.form) {
          modalContainer.style.alignItems = 'center';
          modalContainer.style.justifyContent = isMediumDevice ? 'flex-end' : 'center';
        }
        if (variant === MODAL_VARIANT.info) {
          modalContainer.style.alignItems = 'flex-start';
          if (isMediumDevice) {
            // Since until 768px our modal is essentially in "mobile" layout, we want to align it at the bottom of the screen
            modalContainer.style.justifyContent = 'flex-end';
          }
        }
        modalContainer.style.padding = '8px';
      }
    }

    // Explicitly return undefined if no cleanup is required
    return undefined;
  }, [isMediumDevice, variant, close]);

  useEffect(() => {
    const modal = modalRef.current;

    if (modal) {
      const titleElement = document.getElementById('modal-title');

      if (titleElement) {
        // Use a minimal timeout to ensure the content is ready
        const timeoutId = setTimeout(() => {
          modal.setAttribute('aria-labelledby', titleElement.innerText);
        }, 0);

        return () => clearTimeout(timeoutId); // Clean up to avoid memory leaks
      }
    }
    // Explicitly return undefined if no cleanup is required
    return undefined;
  }, [isVisible]);

  useModalAccessibility(modalRef, isVisible, variant);

  return (
    <KatModal
      className={classNames(
        variant === MODAL_VARIANT.info ? styles.ModalWrapperList : styles.ModalWrapperForm,
        className
      )}
      ref={modalRef}
      visible={isVisible}
      onClose={close}
      footer={footer}
    >
      <span slot="title" />
      {title && (
        <h2 className={styles.Title} id="modal-title">
          {title}
        </h2>
      )}

      {subtitle && <p className={styles.Subtitle}>{subtitle}</p>}
      {children}
    </KatModal>
  );
};
