import classNames from 'classnames';
import React, { type ReactElement, useCallback, useEffect, useRef } from 'react';

import { Icon } from 'src/components/atoms/icon/Icon';
import { IconType } from 'src/components/atoms/icons/icons';
import { CTA_POSITION, type CTA_TYPE, LIST_VARIANT, ListItem } from 'src/components/common';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { shouldRenderFluidPageSection } from 'src/helpers/library-helpers';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './Accordion.module.scss';

export enum ACCORDION_VARIANT {
  MainBanner = 'main-banner',
  AccordionComponent = 'accordion-component',
  Card = 'card',
}

export type AccordionProps = {
  theme?: SECTION_THEME;
  variant?: ACCORDION_VARIANT;
  title?: string;
  titleSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  subtitle?: string;
  content: Array<{
    cta?: {
      href: string;
      icon?: IconType;
      type?: CTA_TYPE;
      label?: string;
      position?: CTA_POSITION;
    };
    image?: ImageAttributes;
    text?: string;
    subtitle?: string;
    secondText?: string;
    links?: Array<{ label: string; url: string }>;
  }>;
};

type AccordionComponentProps = AccordionProps & {
  number?: number;
  height?: number;
  isOpen: boolean;
  onClick(): void;
  onCardHeightUpdate?(value: number, reset?: boolean): void;
};

export function Accordion({
  height,
  number,
  theme = SECTION_THEME.Dark,
  variant = ACCORDION_VARIANT.MainBanner,
  title,
  subtitle,
  content,
  isOpen,
  titleSize = 'h4',
  onClick,
  onCardHeightUpdate,
}: AccordionComponentProps): ReactElement {
  const accordionRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const contentHeight = useRef<number | string>(0);

  const contentId = `${title}-${content[0]?.text?.slice(0, 10)}`;
  const renderFluidPageSection = shouldRenderFluidPageSection();

  const TitleTag = React.createElement(
    titleSize,
    {
      className: classNames(styles.accordionTitle, styles[theme], styles[variant], isOpen && styles.isOpen),
    },
    title
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        onClick();
      }
    },
    [onClick]
  );

  const updateCardHeight = useCallback(() => {
    if (contentRef.current && accordionRef.current) {
      const currentHeight = accordionRef.current?.scrollHeight;
      contentRef.current.style.setProperty('--content-height', 'auto');
      accordionRef.current.style.setProperty('--card-height', 'max-content');
      const maxHeight = accordionRef.current?.scrollHeight || 0;
      contentRef.current.style.setProperty('--content-height', isOpen ? `${contentHeight.current}px` : '0');
      accordionRef.current.style.setProperty('--card-height', `${currentHeight}px`);
      onCardHeightUpdate?.(maxHeight);
    }
  }, [isOpen, onCardHeightUpdate]);

  useEffect(() => {
    if (variant !== ACCORDION_VARIANT.Card) return;

    updateCardHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (variant !== ACCORDION_VARIANT.Card) return undefined;

    window.addEventListener('resize', updateCardHeight);
    return () => {
      window.removeEventListener('resize', updateCardHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCardHeight]);

  useEffect(() => {
    if (variant !== ACCORDION_VARIANT.Card) return;

    if (accordionRef) {
      accordionRef.current?.style.setProperty('--card-height', `${height}px`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);

  useEffect(() => {
    const updateContentHeight = (): void => {
      if (contentRef.current) {
        contentRef.current.style.setProperty('--content-height', isOpen ? 'auto' : '0');
        contentHeight.current = contentRef.current.scrollHeight;

        if (isOpen) {
          contentRef.current.style.setProperty('--content-height', '0');
          setTimeout(() => {
            contentRef.current!.style.setProperty('--content-height', `${contentHeight.current}px`);
          }, 0);
        }
      }
    };

    updateContentHeight();
    window.addEventListener('resize', updateContentHeight);
    return () => {
      window.removeEventListener('resize', updateContentHeight);
    };
  }, [isOpen, contentRef]);

  return (
    <div
      ref={accordionRef}
      className={classNames(
        styles.accordion,
        renderFluidPageSection && styles.hasFluidPaddings,
        styles[theme],
        styles[variant],
        isOpen && styles.isOpen
      )}
    >
      <button
        className={styles.accordionHeader}
        type="button"
        onClick={onClick}
        onKeyDown={onKeyDown}
        aria-controls={contentId}
        aria-expanded={isOpen}
        aria-label={isOpen ? 'Collapse' : 'Expand'}
      >
        {variant !== ACCORDION_VARIANT.Card && TitleTag}
        <div
          className={classNames(
            styles.accordionToggle,
            styles[variant],
            styles[theme],
            styles[isOpen ? 'open' : 'closed']
          )}
        >
          <Icon name={isOpen ? 'minus' : 'plus'} className={styles.accordionIcon} />
        </div>
      </button>
      {variant === ACCORDION_VARIANT.Card && number && (
        <p className={styles.cardNumber}>{number < 10 ? `0${number}` : number}</p>
      )}
      {variant === ACCORDION_VARIANT.Card && TitleTag}
      <div id={contentId} className={styles.content} ref={contentRef} aria-hidden={!isOpen}>
        {subtitle && <p className={classNames(styles.subtitle, styles[theme])}> {subtitle} </p>}
        {variant !== ACCORDION_VARIANT.AccordionComponent && (
          <p className={classNames(styles.accordionContent, styles[theme])}> {content[0]?.text} </p>
        )}
        {variant === ACCORDION_VARIANT.AccordionComponent &&
          content.map((item, index) => (
            <React.Fragment key={`${item.text}-${item.subtitle}`}>
              <ListItem
                key={`${item.text}-${item.subtitle}`}
                theme={theme}
                title={item.subtitle}
                headingSize="h5"
                subtitle={item.text}
                image={item.image}
                links={item.links}
                cta={item.cta}
                isOpen={isOpen}
                variant={LIST_VARIANT.Accordion}
              />

              {index < content.length - 1 && <div className={classNames(styles.listUnderline, styles[theme])} />}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
}
