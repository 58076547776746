import { FunctionComponent, useContext, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { SaveCreditsInterestFormResponse } from '@amzn/coral_com-amazon-sssapinterface-model-operations';
import { KatProgress } from '@amzn/katal-react';
import { useBundle } from '@amzn/react-arb-tools';

import { SSSAPIClientContext } from 'src/clients/SSSAPIClientProvider';
import { BackButton } from 'src/components/atoms';
import { PageSection } from 'src/components/common';
import {
  CreditsInterestForm,
  CreditsInterestFormValues,
} from 'src/components/pages/credits/interest/CreditsInterestForm';
import { ThankYouPage } from 'src/components/templates/thank-you/ThankYouPage';
import { FormStrings, SAVE_FORM_ERROR_LABEL } from 'src/constants';
import {
  COMMON_BUNDLE_NAME,
  CONTACT_FORM_PAGE_BUNDLE_NAME,
  LOADING_ARIA_LABEL,
  SUBMITTING_ARIA_LABEL,
} from 'src/constants/common-constants';
import { t } from 'src/helpers';
import { useApi } from 'src/hooks/use-api';
import { emitCountMetric, logError } from 'src/logger';
import { MONS_SESSION_ID, SAVEASXFORM_ERROR } from 'src/logging-helpers/metrics-constants';
import { getCookie } from 'src/logging-helpers/metrics-helpers';

import styles from './CreditsInterestFormPage.module.scss';

export const CreditsInterestFormPage: FunctionComponent = () => {
  const [formBundle, isFormBundleLoading] = useBundle(CONTACT_FORM_PAGE_BUNDLE_NAME);
  const [commonBundle, isCommonBundleLoading] = useBundle(COMMON_BUNDLE_NAME);

  const { saveCreditsInterestForm } = useContext(SSSAPIClientContext);
  const sessionId = getCookie(MONS_SESSION_ID);

  const [error, setError] = useState<Error>();

  const customErrorHandler = (err: Error) => {
    // istanbul ignore next
    if (err) {
      setError(err);
      // TODO change this metric https://issues.amazon.com/issues/ASX-237
      emitCountMetric(SAVEASXFORM_ERROR, 1);
      logError('CreditsInterestFormPage', 'Error while saving form', err);
    }
  };
  const { isLoading, response, callApi } = useApi<SaveCreditsInterestFormResponse>(customErrorHandler);

  const handleFormSubmit: SubmitHandler<CreditsInterestFormValues> = (formValues) => {
    callApi(saveCreditsInterestForm, formValues, sessionId, formBundle);
  };

  const areBundlesLoading = isCommonBundleLoading || isFormBundleLoading;

  if (areBundlesLoading) {
    // istanbul ignore next
    return (
      <div role="status">
        <KatProgress katAriaLabel={t(commonBundle, LOADING_ARIA_LABEL)} indeterminate size="medium" type="circular" />
      </div>
    );
  }

  // Thank You Page
  if (response && response.id) {
    return <ThankYouPage submissionStatus="success" />;
  }
  if (error) {
    // istanbul ignore next
    return <ThankYouPage submissionStatus="failure" />;
  }

  return (
    <PageSection>
      <div className={styles.backButtonContainer}>
        <BackButton
          variant="default"
          className={styles.backButton}
          label={t(formBundle, FormStrings.CREDIT_FORM_BACK_BUTTON)}
          data-testid="back-button"
        />
      </div>
      <div className={styles.contactFormWrapper}>
        <CreditsInterestForm creditFormBundle={formBundle} handleFormSubmit={handleFormSubmit} />
        {isLoading && (
          <div role="status">
            <KatProgress
              katAriaLabel={t(commonBundle, SUBMITTING_ARIA_LABEL)}
              indeterminate
              size="medium"
              type="circular"
            />
          </div>
        )}
        {error && <div>{t(formBundle, SAVE_FORM_ERROR_LABEL)}</div>}
      </div>
    </PageSection>
  );
};
