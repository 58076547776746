import classNames from 'classnames';
import { useState } from 'react';
import { FieldError, FieldValues } from 'react-hook-form';

import { SelectPill, Icon } from 'src/components/atoms';
import { TextInput } from 'src/components/form-elements';
import { AdditionalFormInputProps } from 'src/interfaces/AdditionalFormInputProps';

import styles from './SelectGroup.module.scss';

interface SelectGroupProps<T, TFieldValues extends FieldValues> {
  label: string;
  options: { value: T; optionLabel: string }[];
  onChange: (value: T, isChecked: boolean) => void;
  errors?: FieldError;
  id: string;
  testId: string;
  sublabel?: string;
  showAdditionalInput?: boolean;
  additionalInputProps?: AdditionalFormInputProps<TFieldValues>;
  initialValues?: Array<T>;
  selectionType?: 'single' | 'multi';
}

export const SelectGroup = <T, TFieldValues extends FieldValues>({
  label,
  options,
  onChange,
  errors,
  id,
  testId,
  sublabel,
  showAdditionalInput = false,
  additionalInputProps,
  initialValues,
  selectionType = 'multi',
}: SelectGroupProps<T, TFieldValues>) => {
  const [selectedValues, setSelectedValues] = useState<T[]>(initialValues ? ([] as T[]).concat(initialValues) : []);

  const handleCheckboxChange = (value: T, isChecked: boolean): void => {
    let updatedValues: T[];

    if (selectionType === 'single') {
      updatedValues = isChecked ? [value] : [];
    } else {
      updatedValues = isChecked ? [...selectedValues, value] : selectedValues.filter((v) => v !== value);
    }

    setSelectedValues(updatedValues);
    onChange(value, isChecked);
  };

  return (
    <div data-testid={testId}>
      <fieldset>
        <legend className={classNames(styles.label, { [styles.error]: errors })} id={id}>
          {label}
        </legend>
        {sublabel && (
          <div className={styles.subLabelContainer}>
            <Icon name="info" className={classNames(styles.subLabelIcon, { [styles.error]: errors })} />
            <p className={classNames(styles.subLabel, { [styles.error]: errors })}>{sublabel}</p>
          </div>
        )}
        <div
          className={classNames(styles.selectGroup, selectionType === 'single' && styles.singleCheck)}
          aria-labelledby={id}
        >
          {options.map(({ value, optionLabel }, index) => (
            <SelectPill
              key={`${optionLabel} ${index}`}
              label={optionLabel}
              onChange={(isChecked) => handleCheckboxChange(value, isChecked)}
              error={Boolean(errors)}
              checked={selectedValues.includes(value)}
              isRound={selectionType === 'single'}
            />
          ))}

          {showAdditionalInput && additionalInputProps && (
            <div className={styles.textInputContainer}>
              <TextInput
                label={additionalInputProps.label}
                inputType="text"
                iconName={additionalInputProps.iconName}
                id={additionalInputProps.id}
                value={additionalInputProps.additionalInputValue}
                {...additionalInputProps.register(additionalInputProps.id, additionalInputProps.registerOptions)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  additionalInputProps.onChange(event.target.value)
                }
                errors={additionalInputProps.error}
              />
            </div>
          )}
        </div>
        {errors && (
          <div className={styles.errorWrapper}>
            <Icon name="error" className={styles.errorIcon} />
            <p className={styles.errorMessage}>{errors.message}</p>
          </div>
        )}
      </fieldset>
    </div>
  );
};
