import classNames from 'classnames';
import type { APITypes } from 'plyr-react';
import React, { useState, useEffect, useRef } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { Icon, PRIMARY_BUTTON_V2_ICON_POSITION, PrimaryButtonV2 } from 'src/components/atoms';
import { TranscriptionFragments, VideoPlayer } from 'src/components/common';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';

import styles from './VideoWithPreviews.module.scss';

type VideoPreview = {
  /**
   * Number expressed as SECONDS corresponding to which part of the video is the preview corresponding to, for example,
   * a time of 52 would indicate that preview should correspond to the second 00:52 of the video
   */
  time: number;
  /**
   * String corresponding to the source of the thumbnail for that preview
   */
  thumbnailSrc: string;
  /**
   * String corresponding to the information text for that preview
   */
  text: string;
  /**
   * String corresponding to the descriptive text for that preview's thumbnail
   */
  thumbnailAlt: string;
};

interface VideoWithPreviewsProps {
  videoHeadline: string;
  previewInformation: string;
  previews: Array<VideoPreview>;
  transcription: Array<TranscriptionFragments>;
  showContent?: boolean;
}

/**
 * This component is the "initial state" of the video component as a whole, it includes video previews and text content
 * together with the VideoPlayer which is the component in charge of rendering the video with its captions and transcription
 */
export const VideoWithPreviews: React.FunctionComponent<VideoWithPreviewsProps> = ({
  videoHeadline,
  previewInformation,
  previews,
  transcription,
  showContent = true,
}) => {
  const plyrRef = useRef<APITypes>(null);
  const videoPlayerRef = useRef<HTMLDivElement>(null);

  const [currentPreview, setCurrentPreview] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const [progress, setProgress] = useState(0);
  const [pausedProgress, setPausedProgress] = useState(0);
  const [arePreviewsAutoscrolling, setArePreviewsAutoscrolling] = useState(true);

  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  // Handle video previews autoscroll
  useEffect(() => {
    if (!previews || !arePreviewsAutoscrolling || !showContent) {
      return undefined;
    }

    const totalDuration = previews.length * 2500;

    // Use pausedProgress as the starting point
    const startTime = Date.now() - (pausedProgress / 100) * totalDuration;

    const interval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const overallProgress = (elapsedTime % totalDuration) / totalDuration;

      setProgress(overallProgress * 100);

      const previewIndex = Math.floor((elapsedTime / 2500) % previews.length);
      setCurrentPreview(previewIndex);
    }, 50);

    return () => clearInterval(interval);
  }, [arePreviewsAutoscrolling, previews, pausedProgress, showContent]);

  // Stop/Play the video previews autoscrolling while saving progress
  const handleAutoscrollToggle = () => {
    if (arePreviewsAutoscrolling) {
      // Pause and save the current progress
      setPausedProgress(progress);
    }
    setArePreviewsAutoscrolling(!arePreviewsAutoscrolling);
  };

  const handleFullScreenEnter = () => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.requestFullscreen();
    }
  };

  const handleFullScreenExit = () => {
    if (plyrRef.current) {
      setIsVideoPlaying(false);
      setArePreviewsAutoscrolling(true);
      plyrRef.current.plyr.pause();
    }
  };

  const handlePlayVideoClick = () => {
    if (plyrRef.current) {
      setIsVideoPlaying(true);
      handleFullScreenEnter();
      plyrRef.current.plyr.play();
    }
  };

  const playVideoFromPreview = (index: number) => {
    if (plyrRef.current && previews) {
      plyrRef.current.plyr.currentTime = previews[index].time;
      setIsVideoPlaying(true);
      handleFullScreenEnter();
      plyrRef.current.plyr.play();
    }
  };

  const handlePreviewClick = (index: number) => {
    setCurrentPreview(index);
    setArePreviewsAutoscrolling(false);
    playVideoFromPreview(index);
  };

  const handlePreviewKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>, index: number) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handlePreviewClick(index);
    }
  };

  return showContent ? (
    <div className={styles.videoContainer}>
      <VideoPlayer
        ref={plyrRef}
        onFullscreenExit={handleFullScreenExit}
        containerRef={videoPlayerRef}
        isVideoPlaying={isVideoPlaying}
        transcription={transcription}
        // TODO: update these strings whenever final assets are available
        videoSrc="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        captionsSrc="https://d3hkct5o8d50yt.cloudfront.net/locale/en-us/resource-type/video/captions-test.vtt"
      />
      {!isVideoPlaying && previews && (
        <img
          src={previews[currentPreview].thumbnailSrc}
          className={styles.videoPoster}
          alt={previews[currentPreview].thumbnailAlt}
          data-testid="active-poster"
        />
      )}
      <div className={styles.videoTextContainer}>
        <h3 className={styles.videoTextHeader}>{videoHeadline}</h3>
        <PrimaryButtonV2
          label={t(commonBundle, 'video_play')}
          iconPosition={PRIMARY_BUTTON_V2_ICON_POSITION.Before}
          icon="play"
          theme={SECTION_THEME.Dark}
          className={styles.videoPlayCta}
          onClick={handlePlayVideoClick}
          testId="play-video-button"
        />
      </div>
      {previews && (
        <div className={styles.videoPreview}>
          <div className={styles.videoPreviewInfoContainer}>
            <span className={styles.videoPreviewInfo}>{previewInformation}</span>
            <span className={styles.videoPreviewInfoDivider}>&nbsp;</span>
            <span className={styles.currentPreviewInfo}>{previews[currentPreview]?.text}</span>
          </div>
          <div className={styles.previewsContainer} data-testid="previews-container">
            <div className={styles.previewsImagesContainer}>
              {previews.map((preview, index) => (
                <button
                  key={index}
                  onClick={() => handlePreviewClick(index)}
                  onKeyDown={(event) => {
                    handlePreviewKeyDown(event, index);
                  }}
                  className={classNames(styles.previewButton, currentPreview === index && styles.activePreview)}
                  type="button"
                  data-testid="preview-button"
                >
                  <img src={preview.thumbnailSrc} className={styles.previewImage} alt={preview.thumbnailAlt} />
                </button>
              ))}
              <div
                className={styles.progressBar}
                style={{
                  background: `linear-gradient(to right, #ADEDC4 ${progress}%, #F2F7F7 ${progress}%)`,
                }}
              />
            </div>
            <button
              className={styles.previewsAutoScrollButton}
              onClick={handleAutoscrollToggle}
              type="button"
              data-testid="previews-autoplay-button"
            >
              <Icon name={arePreviewsAutoscrolling ? 'pause' : 'play'} className={styles.icon} />
            </button>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className={classNames(styles.videoContainer, styles.intro)}>
      <img
        src={previews[0].thumbnailSrc}
        className={classNames(styles.videoPoster, styles.intro)}
        alt={previews[0].thumbnailAlt}
        data-testid="active-poster"
      />
    </div>
  );
};
