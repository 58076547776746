import classNames from 'classnames';
import { type KeyboardEvent as ReactKeyboardEvent, type ReactElement } from 'react';

import { Checkmark } from 'src/components/atoms';

import styles from './SelectPill.module.scss';

interface SelectPillProps {
  label?: string;
  error?: boolean;
  onChange(isChecked: boolean): void;
  checked?: boolean;
  errorMessage?: string;
  isRound?: boolean;
}

function isEnterOrSpacePressed(event: KeyboardEvent | ReactKeyboardEvent): boolean {
  return event.key === 'Enter' || event.key === 'Spacebar';
}

export function SelectPill({
  label,
  error,
  onChange,
  checked = false,
  errorMessage,
  isRound = false,
}: SelectPillProps): ReactElement {
  const handleCheck = (): void => {
    onChange(!checked);
  };

  const handleKeyDown = (event: ReactKeyboardEvent<Element>): void => {
    if (isEnterOrSpacePressed(event)) {
      event.preventDefault();
      handleCheck();
    }
  };

  return (
    <div>
      <button
        data-testid={`checkbox-pill-${label}`}
        className={classNames(styles.selectPill, { [styles.error]: error })}
        onClick={handleCheck}
        onKeyDown={handleKeyDown}
        aria-checked={checked}
        type="button"
        aria-labelledby={`checkbox-pill-label-${label}`}
        role="checkbox"
      >
        <div className={styles.checkbox}>
          <input
            type="checkbox"
            className={styles.input}
            checked={checked}
            onChange={handleCheck}
            onKeyDown={handleKeyDown}
          />
          <Checkmark checked={checked} isRound={isRound} error={error} className={styles.checkmark} />

          <span
            id={`checkbox-pill-label-${label}`}
            data-testid={`checkbox-pill-label-${label}`}
            className={styles.label}
          >
            {label}
          </span>
        </div>
      </button>
      {error && errorMessage && (
        <div id={`checkbox-pill-error-${label}`} className={styles.errorMessage} role="alert">
          {errorMessage}
        </div>
      )}
    </div>
  );
}
