import classNames from 'classnames';
import { useEffect, useRef, useState, type ReactElement } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { AsxImage, Icon, IconType } from 'src/components/atoms';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { t } from 'src/helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './BrandTabs.module.scss';

/**
 * Represents the structure of a brand tab option.
 * @interface BrandTabOption
 */
interface BrandTabOption {
  /** The icon to be displayed for the brand. */
  brandIcon: IconType;
  /** The profile image for the brand's associated user or entity. */
  profileImage: ImageAttributes;
  /** The name displayed on the profile card. */
  profileName: string;
  /** The occupation or description displayed on the profile card. */
  profileOcupation: string;
}

/**
 * Props for the BrandTabs component.
 * @interface BrandTabsProps
 */
export interface BrandTabsProps {
  /** Array of tab options, each representing a brand with associated profile data. */
  options: BrandTabOption[];
  /** Callback function triggered when the active tab changes. */
  onTabChange?: (index: number) => void;
  /** Optional additional class names to apply to the component for styling purposes. */
  className?: string;
}

/**
 * Props for the ProfileCard component.
 * @interface ProfileCardProps
 */
interface ProfileCardProps {
  /** Indicates whether the profile card is active or highlighted. */
  isActive: boolean;
  /** The profile image to display. */
  profileImage: ImageAttributes;
  /** The name to display on the profile card. */
  profileName: string;
  /** The occupation or description to display on the profile card. */
  profileOcupation: string;
  /** Optional: Whether a visual divider should be included above the card. */
  hasDivisor?: boolean;
}

/**
 * The card is rendered within BrandTabs only on certain screen sizes.
 */
function ProfileCard({ isActive, profileImage, profileName, profileOcupation, hasDivisor }: ProfileCardProps) {
  return (
    <div className={classNames(styles.profileWrapper, isActive && styles.isActive)} aria-hidden={!isActive}>
      {hasDivisor && <div className={styles.divisor} />}
      <AsxImage className={styles.profileImage} src={profileImage.src} alt={profileImage.alt} />
      <div className={styles.profileCopyWrapper}>
        <p className={styles.profileName}>{profileName}</p>
        <p className={styles.profileOcupation}>{profileOcupation}</p>
      </div>
    </div>
  );
}

export function BrandTabs({ options, onTabChange, className }: BrandTabsProps): ReactElement {
  const [activeTab, setActiveTab] = useState(0);
  const [showButtonRight, setShowButtonRight] = useState(false);
  const [showButtonLeft, setShowButtonLeft] = useState(false);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

  const anchorListRef = useRef<HTMLDivElement>(null);
  const brandTab = useRef<HTMLButtonElement>(null);

  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  const { isLargeDevice } = useDeviceSize();

  const scrollDistance = brandTab.current?.offsetWidth;

  const handleTabClick = (index: number) => {
    setActiveTab(index);

    if (onTabChange) {
      onTabChange(index);
    }
  };

  const showRightButtonLogic = () => {
    const anchorListElement = anchorListRef.current;

    if (anchorListElement) {
      setShowButtonRight(anchorListElement.scrollWidth > anchorListElement.clientWidth);
    }
  };

  useEffect(() => {
    const load = () => {
      showRightButtonLogic();
    };

    window.addEventListener('load', load);
    return () => window.removeEventListener('load', load);
  }, []);

  useEffect(() => {
    const anchorListElement = anchorListRef.current;

    const handleResize = () => {
      showRightButtonLogic();

      if (anchorListElement) {
        setIsScrolledToEnd(
          anchorListElement.scrollLeft + anchorListElement.clientWidth >= anchorListElement.scrollWidth
        );
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const anchorListElement = anchorListRef.current;

    if (anchorListElement) {
      const handleListScroll = () => {
        setIsScrolledToEnd(
          anchorListElement.scrollLeft + anchorListElement.clientWidth >= anchorListElement.scrollWidth
        );
        setShowButtonLeft(anchorListElement.scrollLeft > 0);
      };

      anchorListElement.addEventListener('scroll', handleListScroll);

      return () => anchorListElement.removeEventListener('scroll', handleListScroll);
    }

    return undefined;
  }, []);

  // istanbul ignore next
  const handleScrollRight = () => {
    const anchorListElement = anchorListRef.current;

    if (anchorListElement) {
      anchorListElement.scrollBy({ left: scrollDistance, behavior: 'smooth' });
    }
  };

  // istanbul ignore next
  const handleScrollLeft = () => {
    const anchorListElement = anchorListRef.current;

    if (anchorListElement && scrollDistance) {
      anchorListElement.scrollBy({ left: -scrollDistance, behavior: 'smooth' });
    }
  };

  return (
    <div className={classNames(styles.brandTabsContainer, className)}>
      <div className={styles.brandTabsWrapper}>
        <div className={styles.brandTabs} role="tablist" ref={anchorListRef}>
          {options.map((option, index) => {
            const isActive = index === activeTab;
            const tabId = `tab-${index + 1}`;
            const panelId = `tabpanel-${index + 1}`;

            return (
              <button
                data-testid={`button ${option.brandIcon}`}
                ref={brandTab}
                type="button"
                role="tab"
                aria-selected={isActive}
                onClick={() => handleTabClick(index)}
                className={classNames(styles.brandTab, isActive && styles.isActive)}
                id={tabId}
                aria-controls={panelId}
                key={`${option.profileName} ${index}`}
              >
                <Icon className={styles.brandIcon} name={option.brandIcon} />
                {isLargeDevice && (
                  <ProfileCard
                    hasDivisor
                    isActive={isActive}
                    profileImage={option.profileImage}
                    profileName={option.profileName}
                    profileOcupation={option.profileOcupation}
                  />
                )}
              </button>
            );
          })}
        </div>
        <div className={classNames(styles.buttonWrapperLeft, showButtonLeft && styles.showButtonLeft)}>
          <button
            type="button"
            className={styles.scrollButton}
            onClick={handleScrollLeft}
            aria-label={t(commonBundle, 'button_previous')}
            data-testid="left-button"
          >
            <Icon name="chevronRight" />
          </button>
        </div>
        <div
          className={classNames(
            styles.buttonWrapperRight,
            showButtonRight && !isScrolledToEnd && styles.showButtonRight
          )}
        >
          <button
            type="button"
            className={styles.scrollButton}
            onClick={handleScrollRight}
            aria-label={t(commonBundle, 'button_next')}
            data-testid="right-button"
          >
            <Icon name="chevronRight" />
          </button>
        </div>
      </div>

      {!isLargeDevice &&
        options.map((option, index) => {
          const isActive = index === activeTab;
          return (
            index === activeTab && (
              <ProfileCard
                isActive={isActive}
                profileImage={option.profileImage}
                profileName={option.profileName}
                profileOcupation={option.profileOcupation}
              />
            )
          );
        })}
    </div>
  );
}
