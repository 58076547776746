import { ElementRef, useRef } from 'react';

import { KatModal } from '@amzn/katal-react';

// When calling a useRef and reading it within a useEffect the value overrides
// any jest mock/spy that we have in place, so as a workaround we can
// return the current value of the ref in a different variable, so we don't mock the useRef
// but this hook instead, effectively allowing us to mock this ref value
export const useTooltipModalWrapperRef = () => {
  const modalRef = useRef<ElementRef<typeof KatModal>>(null);

  return {
    modalRef,
    modal: modalRef.current,
  };
};
