import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { THEME } from 'src/enums/theme';
import HamburgerIcon from 'src/icons/hamburger.svg';

import styles from './HamburgerButton.module.scss';

interface HamburgerButtonProps {
  ariaLabel: string;
  onClick: () => void;
  theme?: THEME;
}

export const HamburgerButton: FunctionComponent<HamburgerButtonProps> = ({
  ariaLabel,
  onClick,
  theme = THEME.LIGHT,
}) => (
  <button
    className={classNames(styles.hamburgerButton, styles[theme])}
    type="button"
    aria-label={ariaLabel}
    onClick={onClick}
  >
    <HamburgerIcon className={styles.hamburgerIcon} role="img" />
  </button>
);
