import { useEffect, useMemo, useState } from 'react';

const NAVBAR_ID = 'sc-navbar-container';

interface UseHeaderVisibilityResponse {
  isHeaderHidden: boolean;
  isHeaderVisible: boolean;
  headerHeight: number;
}

export const useHeaderVisibility = (): UseHeaderVisibilityResponse => {
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  // Exposing both to be used as semantically convinient
  const isHeaderVisible = useMemo(() => !isHeaderHidden, [isHeaderHidden]);

  // useEffect for observing header visibility
  useEffect(() => {
    const header = document.getElementById(NAVBAR_ID);

    if (!header) return;

    const updateHeaderState = () => {
      const currentIsHeaderHidden = header.getAttribute('aria-hidden') === 'true';
      setIsHeaderHidden(currentIsHeaderHidden);
      setHeaderHeight(header.offsetHeight);
    };

    // Initial call to set state
    updateHeaderState();

    // Create a MutationObserver to watch for changes in the header's aria-hidden attribute
    const observer = new MutationObserver(updateHeaderState);

    // Update the state when aria-hidden changes
    observer.observe(header, { attributes: true, attributeFilter: ['aria-hidden'] });

    // Cleanup the observer on component unmount
    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, []);

  return { isHeaderHidden, isHeaderVisible, headerHeight };
};
