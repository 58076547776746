import { useMemo } from 'react';

import { ASX_DEBORAH_LANDING_PAGE } from 'src/constants';
import { THEME } from 'src/enums/theme';

type RouteThemeMap = {
  [key: string]: {
    value: THEME;
    // Optional weblab to be evaluated by the calling component before applying theme.
    weblab?: string;
  };
};

export const DEFAULT_THEME = { value: THEME.LIGHT };

// Define the default route colors
const THEME_BY_ROUTE: RouteThemeMap = {
  '/': DEFAULT_THEME,
  '/credits': { value: THEME.DARK, weblab: ASX_DEBORAH_LANDING_PAGE },
};

/**
 * Returns theme override data to be used for the current page route.
 * Weblabs should be evaluated by the calling component before applying theme.
 * @returns Theme override data
 */
export const useThemeOverride = () => {
  // Get current path without any trailing slashes
  const currentPath = window.location.pathname.replace(/\/+$/, '');

  // Get the color for the current route, fallback to default color if not found
  const theme = useMemo(() => THEME_BY_ROUTE[currentPath] || DEFAULT_THEME, [currentPath]);

  return theme;
};
