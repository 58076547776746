// The purpose of this file is creating the custom controls for our video player since the default ones
// for example, do not include one for transcription or do not include our icons, also we took advantage
// of these custom controls to add data-plyr properties for styles and testing, they are differentiated between
// desktop and mobile since their layout is different depending on the resolution

const playButtonControl = `
<button type="button" class="plyr__controls__item plyr__control" aria-label="Play" data-plyr="play">
  <svg class="icon--pressed" role="presentation" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M11 8H8V16H11V8Z" fill="currentColor"/>
    <path d="M16 8H13V16H16V8Z" fill="currentColor"/>
  </svg>
  <svg class="icon--not-pressed" role="presentation" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M16.2111 11.1056L9.44721 7.72361C8.78231 7.39116 8 7.87465 8 8.61804V15.382C8 16.1253 8.78231 16.6088 9.44722 16.2764L16.2111 12.8944C16.9482 12.5259 16.9482 11.4741 16.2111 11.1056Z" fill="currentColor"/>
  </svg>
</button>
`;

const progressControl = `
<div class="plyr__controls__item plyr__progress__container" data-plyr="progress">
  <div class="plyr__progress">
    <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
    <progress class="plyr__progress__buffer" min="0" max="100" value="0" role="progressbar">% buffered</progress>
  </div>
</div>
`;

const currentTimeControl = `
<div data-plyr="current-time" class="plyr__controls__item plyr__time--current plyr__time" aria-label="Current time">00:00</div>
`;

const volumeControl = `
<div class="plyr__controls__item plyr__volume" data-plyr="volume-control">
  <button type="button" class="plyr__control plyr__controls__item" aria-label="Mute" data-plyr="mute">
    <svg class="icon--pressed" role="presentation" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 8H3V16H7L13 20V4L7 8ZM11 7.73703L7.60555 10H5V14H7.60555L11 16.263V7.73703Z" fill="currentColor"/>
        <path d="M16.5859 12L14.293 9.70712L15.7072 8.29291L18.0001 10.5858L20.293 8.29291L21.7072 9.70712L19.4143 12L21.7072 14.2929L20.293 15.7071L18.0001 13.4142L15.7072 15.7071L14.293 14.2929L16.5859 12Z" fill="currentColor"/>
    </svg>

    <svg class="icon--not-pressed" role="presentation" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 8H7L13 4V20L7 16H3V8ZM7.60555 10L11 7.73703V16.263L7.60555 14H5V10H7.60555Z" fill="currentColor"/>
        <path d="M15 6.08295V4.06189C18.9463 4.55399 22 7.92038 22 12C22 16.0796 18.9463 19.446 15 19.9381V17.917C17.8377 17.441 20 14.973 20 12C20 9.027 17.8377 6.55903 15 6.08295Z" fill="currentColor"/>
        <path d="M17 12C17 13.3062 16.1652 14.4175 15 14.8293V9.1707C16.1652 9.58254 17 10.6938 17 12Z" fill="currentColor"/>
    </svg>
  </button>
  <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume">
</div>
`;

const captionsControl = `
<button type="button" class="plyr__control plyr__controls__item" data-plyr="captions">
  <svg class="icon--pressed" role="presentation" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 5C0 3.89543 0.895431 3 2 3H22C23.1046 3 24 3.89543 24 5V19C24 20.1046 23.1046 21 22 21H2C0.89543 21 0 20.1046 0 19V5ZM6 10H9V11H11V10C11 8.89543 10.1046 8 9 8H6C4.89543 8 4 8.89543 4 10V14C4 15.1046 4.89543 16 6 16H9C10.1046 16 11 15.1046 11 14V13H9V14H6V10ZM18 10H15V14H18V13H20V14C20 15.1046 19.1046 16 18 16H15C13.8954 16 13 15.1046 13 14V10C13 8.89543 13.8954 8 15 8H18C19.1046 8 20 8.89543 20 10V11H18V10Z" fill="currentColor"/>
  </svg>
  <svg class="icon--not-pressed" role="presentation" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M6 10H9V11H11V10C11 8.89543 10.1046 8 9 8H6C4.89543 8 4 8.89543 4 10V14C4 15.1046 4.89543 16 6 16H9C10.1046 16 11 15.1046 11 14V13H9V14H6V10Z" fill="currentColor"/>
    <path d="M18 10H15V14H18V13H20V14C20 15.1046 19.1046 16 18 16H15C13.8954 16 13 15.1046 13 14V10C13 8.89543 13.8954 8 15 8H18C19.1046 8 20 8.89543 20 10V11H18V10Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 3C0.895431 3 0 3.89543 0 5V19C0 20.1046 0.89543 21 2 21H22C23.1046 21 24 20.1046 24 19V5C24 3.89543 23.1046 3 22 3H2ZM22 5H2L2 19H22V5Z" fill="currentColor"/>
  </svg>
</button>
`;

const transcriptControl = `
<button type="button" class="plyr__control plyr__controls__item" data-plyr="transcript">
  <svg class="icon--pressed" role="presentation" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 3C0.895431 3 0 3.89543 0 5V19C0 20.1046 0.89543 21 2 21H22C23.1046 21 24 20.1046 24 19V5C24 3.89543 23.1046 3 22 3H2ZM4 10H8V12H4V10ZM20 14H16V16H20V14ZM10 10H20V12H10V10ZM14 14H4V16H14V14Z" fill="currentColor"/>
    </svg>

    <svg class="icon--not-pressed" role="presentation" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M4 10H8V12H4V10Z" fill="currentColor"/>
          <path d="M20 14H16V16H20V14Z" fill="currentColor"/>
          <path d="M10 10H20V12H10V10Z" fill="currentColor"/>
          <path d="M14 14H4V16H14V14Z" fill="currentColor"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2 3C0.895431 3 0 3.89543 0 5V19C0 20.1046 0.89543 21 2 21H22C23.1046 21 24 20.1046 24 19V5C24 3.89543 23.1046 3 22 3H2ZM22 5H2L2 19H22V5Z" fill="currentColor"/>
    </svg>
</button>
`;

export const desktopControls = `
<div class="plyr__controls" data-plyr="controls">
  ${playButtonControl}
  ${progressControl}
  ${currentTimeControl}
  ${volumeControl}
  ${captionsControl}
  ${transcriptControl}
</div>
`;

export const mobileControls = `
<div class="plyr__controls" data-plyr="controls">
  ${playButtonControl}
  ${progressControl}
  <div data-plyr="mobile-controls">
    <div>
      ${currentTimeControl}
    </div>
    <div>
      ${volumeControl}
      ${captionsControl}
      ${transcriptControl}
    </div>
  </div>
</div>
`;
