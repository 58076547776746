import { ResourceType } from '@amzn/coral_com-amazon-sssapinterface-model';

import { getLibraryDomainRoot } from 'src/helpers';

export const BACK_BUTTON_LABEL = 'backButton_label';

// Panther Resource Bundles
export const COMMON_BUNDLE_NAME = 'common';
export const CREDITS_BUNDLE_NAME = 'pages.creditspage';
export const HOMEPAGE_MESSAGE_BUNDLE_NAME = 'pages.homepage';
export const ASSET_GRAPHIC_MESSAGE_BUNDLE_NAME = 'assetgraphic';
export const FOCUS_AREA_PAGES_BUNDLE_NAME = 'pages.focusarea';
export const SEARCH_RESULTS_PAGE_BUNDLE_NAME = 'pages.searchresultspage';
export const GETTING_STARTED_PAGE_BUNDLE_NAME = 'pages.gettingstartedpage';
export const SCIENCE_EXCHANGE_PAGE_BUNDLE_NAME = 'pages.scienceexchange';
export const MEASUREMENT_PAGE_BUNDLE_NAME = 'pages.measurement';
export const DATA_INITIATIVE_PAGE_BUNDLE_NAME = 'pages.datainitiativepage';
export const CONTACT_FORM_PAGE_BUNDLE_NAME = 'pages.contactform';
export const NOT_FOUND_RESOURCE_BUNDLE_NAME = 'templates.notfound';
export const ERROR_WRAPPER_RESOURCE_BUNDLE_NAME = 'templates.error';
export const HMD_RESOURCE_BUNDLE_NAME = 'templates.hmd';
export const SUBMITTING_ARIA_LABEL = 'submitting_aria_label';
export const LOADING_ARIA_LABEL = 'loading_aria_label';

export const SLIDER_PAGINATION_LABEL = 'sliderPagination_label';

// NOTE - if a new bundle is added for a ResourceType, please add to Map below
export const POLICY_RESOURCE_BUNDLE_NAME = 'resources.policy';
export const PLAYBOOK_RESOURCE_BUNDLE_NAME = 'resources.playbook';
export const SECONDARY_HERO_RESOURCE_BUNDLE_NAME = 'resources.secondaryhero';
export const CASE_STUDY_RESOURCE_BUNDLE_NAME = 'resources.casestudy';
export const METHODOLOGY_RESOURCE_BUNDLE_NAME = 'resources.methodology';
export const GUIDANCE_RESOURCE_BUNDLE_NAME = 'resources.guidance';
export const COLLABORATORS_RESOURCE_BUNDLE_NAME = 'resources.collaborators';
export const TOOL_RESOURCE_BUNDLE_NAME = 'resources.tool';
export const DATA_RESOURCE_BUNDLE_NAME = 'resources.data';
export const RESOURCE_TYPE_TO_BUNDLE_MAP: { [K in ResourceType]?: string } = {
  [ResourceType.PLAYBOOK]: PLAYBOOK_RESOURCE_BUNDLE_NAME,
  [ResourceType.POLICY]: POLICY_RESOURCE_BUNDLE_NAME,
  [ResourceType.SECONDARY_HERO]: SECONDARY_HERO_RESOURCE_BUNDLE_NAME,
  [ResourceType.CASE_STUDY]: CASE_STUDY_RESOURCE_BUNDLE_NAME,
  [ResourceType.METHODOLOGY]: METHODOLOGY_RESOURCE_BUNDLE_NAME,
  [ResourceType.GUIDANCE]: GUIDANCE_RESOURCE_BUNDLE_NAME,
  [ResourceType.COLLABORATORS]: COLLABORATORS_RESOURCE_BUNDLE_NAME,
  [ResourceType.TOOL]: TOOL_RESOURCE_BUNDLE_NAME,
  [ResourceType.DATA]: DATA_RESOURCE_BUNDLE_NAME,
};

export const CLOUDFRONT_PREFIX = getLibraryDomainRoot();

// Weblab and other temporary constants
export const SUBTITLE_CLASS = 'asx-subtitle-text';
export const CTA_CLASS = 'asx-cta-text';
