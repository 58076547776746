import { FunctionComponent } from 'react';

import { Heading, HEADING_SIZE } from 'src/components/atoms';
import { AsxImage } from 'src/components/atoms/asx-image/AsxImage';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './HeroAsset.module.scss';

interface HeroAssetProps {
  /**
   * String title displayed inside the card, at the top left
   */
  title: string;
  /**
   * Object represting the image displayed in the tab
   */
  image: ImageAttributes;
}

export const HeroAsset: FunctionComponent<HeroAssetProps> = ({ title, image }) => (
  <div className={styles.heroAsset}>
    <Heading className={styles.heading} as="h4" size={HEADING_SIZE.h2}>
      {title}
    </Heading>
    <AsxImage className={styles.image} {...image} />
  </div>
);
