import classNames from 'classnames';
import { motion, useAnimation } from 'framer-motion';
import { ReactElement, useEffect, useRef, useState, useCallback } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { PrimaryButtonV2 } from 'src/components/atoms';
import { VideoWithPreviews } from 'src/components/common';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { parseTextFragments, t } from 'src/helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import styles from './AnimatedHeroBanner.module.scss';
import { runAnimationSequence } from './BannerAnimation';
import { heroBannerSlideshowImages, videoPreviews, videoTranscription } from './BannerHelpers';
import { HeroOutro, HeroOutroProps } from './hero-outro/HeroOutro';

type AnimatedHeroBannerProps = {
  /* The title in this component is split into 2 fragments, the first one being normal text and the second one having a different
  color, we scan for any string wrapped in "*" to split it */
  title: string;
  /* This subtitle is the text that can be seen between the title and the CTA */
  subtitle: string;
  ctaLabel: string;
  /* This is the headline used for the video inside the banner */
  videoHeadline: string;
  /* This is the first string of the preview container, it provides context and is static */
  previewInformation: string;
} & { outro: HeroOutroProps };

// This component functions as the introduction of the page, it counts with an scroll triggered animation that plays only once
// when on desktop, that animation transitions from the initial hero banner into a video component, it also counts with an outro
// section that has a modal in it. There's no animation/transition between the video and the outro section.
export function AnimatedHeroBanner({
  title,
  subtitle,
  ctaLabel,
  videoHeadline,
  previewInformation,
  outro,
}: AnimatedHeroBannerProps): ReactElement {
  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  const { fragment1, fragment2 } = parseTextFragments(title);

  const imageSlideshowControls = useAnimation();
  const finalSlideControls = useAnimation();
  const animatedHeroBannerRef = useRef<HTMLDivElement | null>(null);

  const [hasAnimated, setHasAnimated] = useState(false);
  const [triggerIntroAnimation, setTriggerIntroAnimation] = useState(false);
  const [hasIntroAnimationEnded, setHasIntroAnimationEnded] = useState(false);
  const [isAnimationInProgress, setIsAnimationInProgress] = useState(false);
  const [hasFinalSlideAnimationEnded, setHasFinalSlideAnimationEnded] = useState(false);

  const { isSmallDesktop } = useDeviceSize();

  // Store the initial "desktop" state
  const isInitiallyDesktop = useRef(isSmallDesktop);

  // Compute whether animation logic should trigger
  const shouldAnimate = isSmallDesktop && isInitiallyDesktop.current && (!hasAnimated || isAnimationInProgress);

  useEffect(() => {
    // Wait for bundle to load so we don't animate empty strings
    if (commonBundle) {
      // Start the intro animation
      setTriggerIntroAnimation(true);
    }
  }, [commonBundle]);

  // Use the extracted animation sequence from the helper function
  const startAnimationSequence = useCallback(
    () =>
      runAnimationSequence({
        imageSlideshowControls,
        finalSlideControls,
        onScrollTo: (top) => window.scrollTo({ top, behavior: 'instant' as ScrollBehavior & { instant: 'instant' } }),
        setHasAnimated,
        setIsAnimationInProgress,
      }),
    [imageSlideshowControls, finalSlideControls]
  );

  useEffect(() => {
    if (!isSmallDesktop || !isInitiallyDesktop.current || hasAnimated) {
      // No animations for non-desktop users, users not initially on desktop, or users who already animated
      return undefined;
    }

    // Prevent scrolling while animation is pending
    if (!isAnimationInProgress) {
      document.body.style.overflow = 'hidden';
    }

    const handleScrollDown = (event: WheelEvent) => {
      if (isAnimationInProgress || hasAnimated || !hasIntroAnimationEnded) return;

      if (event.deltaY > 0) {
        startAnimationSequence();
      }
    };

    // Attach scroll listener for starting animation
    window.addEventListener('wheel', handleScrollDown, { passive: false });

    return () => {
      // Cleanup listener on component unmount
      window.removeEventListener('wheel', handleScrollDown);
    };
  }, [isSmallDesktop, isAnimationInProgress, hasAnimated, hasIntroAnimationEnded, startAnimationSequence]);

  const handleFinalSlideAnimationComplete = () => {
    setHasFinalSlideAnimationEnded(true);
    // Restore scrolling on animation end
    document.body.style.overflow = '';
  };

  const introVariants = {
    hidden: { y: '100%', opacity: 0 },
    visible: {
      y: '0%',
      opacity: 1,
      transition: { duration: 0.5, ease: 'easeOut' },
    },
  };

  return (
    <>
      <div className={styles.animatedHeroBanner} ref={animatedHeroBannerRef}>
        {triggerIntroAnimation && (
          <motion.div
            className={styles.bannerContent}
            variants={introVariants}
            initial="hidden"
            animate="visible"
            onAnimationComplete={() => setHasIntroAnimationEnded(true)}
          >
            <h1 className={styles.title} data-testid="animated-hero-banner-title">
              {fragment1} <span className={styles.titleFragment}>{fragment2}</span>
            </h1>
            <p className={styles.subtitle} data-testid="animated-hero-banner-subtitle">
              {subtitle}
            </p>
            <PrimaryButtonV2 label={ctaLabel} className={styles.cta} icon="chevronRight" theme={SECTION_THEME.Dark} />
            <p className={styles.scrollDown} data-testid="animated-hero-banner-scrolldown">
              {t(commonBundle, 'primaryHeroScroll')}
            </p>
          </motion.div>
        )}
      </div>

      {shouldAnimate ? (
        <div
          className={classNames(styles.animationContainer, hasFinalSlideAnimationEnded && styles.hidden)}
          data-testid="animated-hero-banner-animation-container"
        >
          <motion.div
            className={classNames(styles.imageSlideshowWrapper, hasFinalSlideAnimationEnded && styles.hidden)}
            initial={{ y: 0 }}
            animate={imageSlideshowControls}
            data-testid="animated-hero-banner-slideshow-element"
          >
            {heroBannerSlideshowImages.map((slide, index) => (
              <motion.div
                key={index}
                className={classNames(styles.imageSlideContainer, hasFinalSlideAnimationEnded && styles.hidden)}
                transition={{
                  delay: 0.3,
                  duration: 1,
                  ease: 'easeInOut',
                }}
              >
                <img
                  src={slide.imageSrc}
                  alt=""
                  className={styles.slideImage}
                  data-testid="animated-hero-banner-slide-image"
                />
              </motion.div>
            ))}
            <motion.div
              className={styles.finalSlideContainer}
              initial={{
                width: '40vw',
                height: '40vh',
                marginBottom: '30vh',
                borderRadius: '40px',
                overflow: 'hidden',
              }}
              animate={finalSlideControls}
              onAnimationComplete={handleFinalSlideAnimationComplete}
              data-testid="animated-hero-banner-final-slide-container"
            >
              <VideoWithPreviews
                videoHeadline={videoHeadline}
                previewInformation={previewInformation}
                previews={videoPreviews}
                transcription={videoTranscription}
                showContent={hasFinalSlideAnimationEnded}
              />
            </motion.div>
          </motion.div>
        </div>
      ) : (
        <>
          <div className={styles.videoWithPreviewsWrapper} data-testid="video-with-previews-container">
            <VideoWithPreviews
              videoHeadline={videoHeadline}
              previewInformation={previewInformation}
              previews={videoPreviews}
              transcription={videoTranscription}
              showContent
            />
          </div>
          <HeroOutro {...outro} />
        </>
      )}
    </>
  );
}
