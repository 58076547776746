import classNames from 'classnames';
import React, { type ReactElement, useCallback, useState } from 'react';

import {
  Accordion,
  type AccordionProps,
  IconType,
  PRIMARY_BUTTON_V2_ICON_POSITION,
  PRIMARY_BUTTON_V2_SIZE,
  PrimaryButtonV2,
} from 'src/components/atoms';
import { RateButton } from 'src/components/atoms/rate-button/RateButton';
import { GridImage } from 'src/components/common';
import { HmdModal } from 'src/components/templates/hmd/HmdModal';
import { SUBTITLE_CLASS } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { shouldRenderHmd } from 'src/helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './MainBanner.module.scss';

interface MainBannerProps extends React.HTMLAttributes<HTMLDivElement> {
  theme?: SECTION_THEME;
  title?: string;
  titleSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  subtitle?: string;
  accordions?: Array<AccordionProps>;
  cta?: {
    icon: IconType;
    label: string;
    href: string;
    target?: string;
  };
  image: ImageAttributes;
  className?: string;
  rateable?: boolean;
  resourceId?: string;
}

export function MainBanner({
  theme = SECTION_THEME.Dark,
  title,
  subtitle,
  accordions,
  cta,
  image,
  className,
  titleSize = 'h3',
  rateable,
  resourceId,
  ...props
}: MainBannerProps): ReactElement {
  const { isSmallDesktop } = useDeviceSize();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [openAccordionIndex, setOpenAccordionIndex] = useState<number>(0);

  const TitleTag = React.createElement(titleSize, { className: classNames(styles.title, styles[theme]) }, title);

  const isRateable = rateable && shouldRenderHmd() && resourceId;

  const handleAccordionClick = useCallback((index: number) => {
    setOpenAccordionIndex((previousIndex) => (previousIndex === index ? -1 : index));
  }, []);

  const onAccordionClick = useCallback(
    (index: number) => () => {
      handleAccordionClick(index);
    },
    [handleAccordionClick]
  );

  const ctaButtonSize = (): PRIMARY_BUTTON_V2_SIZE => {
    if (isSmallDesktop) {
      return PRIMARY_BUTTON_V2_SIZE.Regular;
    }

    if (isRateable) {
      return PRIMARY_BUTTON_V2_SIZE.Wide;
    }

    return PRIMARY_BUTTON_V2_SIZE.Small;
  };

  return (
    <>
      <div
        className={classNames(styles.MainBanner, styles[theme], !subtitle && styles.noSubtitle, className)}
        {...(props as React.HTMLAttributes<HTMLDivElement>)}
      >
        <div className={styles.titleContainer}>
          {TitleTag}
          {isSmallDesktop && isRateable && (
            <div>
              <RateButton theme={SECTION_THEME.Green_Primary} setIsModalVisible={setIsModalVisible} />
            </div>
          )}
        </div>
        {subtitle && <p className={classNames(styles.subtitle, styles[theme], SUBTITLE_CLASS)}>{subtitle}</p>}
        <div className={styles.accordionsWrapper}>
          <div className={styles.accordionsList}>
            {accordions?.map((accordion, index) => {
              const key = `${accordion.title}-${index}`;
              return (
                <Accordion
                  key={key}
                  theme={theme}
                  isOpen={index === openAccordionIndex}
                  onClick={onAccordionClick(index)}
                  {...accordion}
                />
              );
            })}
          </div>
          <div className={!isSmallDesktop && isRateable && styles.ctaContainer}>
            {cta && (
              <PrimaryButtonV2
                className={styles.cta}
                link={cta.href}
                target={cta.target ? cta.target : '_blank'}
                icon={cta.icon}
                label={cta.label}
                theme={theme}
                buttonSize={ctaButtonSize()}
                isBold
              />
            )}
            {!isSmallDesktop && isRateable && (
              <div>
                <RateButton
                  theme={SECTION_THEME.Green_Primary}
                  setIsModalVisible={setIsModalVisible}
                  buttonSize={PRIMARY_BUTTON_V2_SIZE.Wide}
                  iconPosition={PRIMARY_BUTTON_V2_ICON_POSITION.After}
                />
              </div>
            )}
          </div>
        </div>
        <GridImage className={styles.gridImage} key={`${image.src}-${image.alt}`} theme={theme} image={image} />
      </div>
      {isRateable && (
        <HmdModal
          key={resourceId}
          isVisible={isModalVisible}
          setIsVisible={setIsModalVisible}
          metadata={{ resourceId, resourceTitle: title }}
        />
      )}
    </>
  );
}
