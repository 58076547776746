import { cubicBezier, animate, useInView } from 'framer-motion';
import { useRef, useState, useCallback, useEffect } from 'react';

import { ScrollAnimator, SCROLL_EASING_FUNCTIONS } from 'src/anim/scrollToAnim';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

export const useTextAndCard = () => {
  const self = useRef<HTMLDivElement | null>(null);
  const titleCardRef = useRef({} as HTMLDivElement);
  const descriptionRef = useRef({} as HTMLDivElement);
  const smallImageRef = useRef({} as HTMLDivElement);
  const bigImageRef = useRef({} as HTMLDivElement);

  const [isAnimating, setIsAnimating] = useState(false);
  const [scrollPosition, setScrollPosition] = useState<'above' | 'below' | null>(null);
  const [elementInView, setElementInView] = useState<'card' | 'description' | null>(null);

  const selfIsInView = useInView(self, { amount: 0.7 });

  const animationDelay = 0.3;
  const animationDuration = 1;

  const { isSmallDesktop } = useDeviceSize();

  const softEaseIn = cubicBezier(0.22, 1, 0.36, 1);

  const getBody = () => document.getElementsByTagName('body')[0] as HTMLElement | undefined;

  const lockScroll = useCallback(() => {
    const body = getBody();
    if (!body) return;

    body.style.overflow = 'hidden';
    body.style.height = 'calc(100vh + 20px)';
  }, []);

  const unlockScroll = useCallback(() => {
    const body = getBody();
    if (!body) return;

    body.style.removeProperty('overflow');
    body.style.removeProperty('height');
  }, []);

  const handleCardToDescription = () => {
    if (isAnimating) return;

    setIsAnimating(true);
    animate(
      titleCardRef.current,
      { y: ['0', '-100%'] },
      {
        duration: animationDuration,
        delay: animationDelay,
        ease: softEaseIn,
      }
    );

    if (isSmallDesktop) {
      animate(
        smallImageRef.current,
        {
          bottom: ['50px', '594px'],
          left: ['72px', '438px'],
          opacity: [0.6, 1],
        },
        {
          duration: animationDuration,
          delay: animationDelay,
          ease: softEaseIn,
        }
      );
    } else {
      animate(
        smallImageRef.current,
        { top: ['30%', '35%'], left: ['-10px', '25px'], opacity: [0, 1] },
        {
          duration: animationDuration,
          delay: animationDelay,
          ease: softEaseIn,
        }
      );
    }

    if (isSmallDesktop) {
      animate(
        bigImageRef.current,
        {
          bottom: ['300px', '275px'],
          right: ['80px', '306px'],
          opacity: [0.5, 1],
        },
        {
          duration: animationDuration,
          delay: animationDelay,
          ease: softEaseIn,
        }
      );
    } else {
      animate(
        bigImageRef.current,
        { bottom: ['20%', '27%'], right: ['-10px', '25px'], opacity: [0, 1] },
        {
          duration: animationDuration,
          delay: animationDelay,
          ease: softEaseIn,
        }
      );
    }

    animate(
      descriptionRef.current,
      { y: ['0', '-100%'], opacity: [0, 1] },
      {
        duration: animationDuration,
        delay: animationDelay,
        ease: softEaseIn,
        onComplete: () => {
          setIsAnimating(false);
          setScrollPosition('below');
          setElementInView('description');
        },
      }
    );
  };

  const handleDescriptionToCard = () => {
    if (isAnimating) return;

    setIsAnimating(true);
    animate(
      descriptionRef.current,
      { y: ['-100%', '0'], opacity: [1, 0] },
      {
        duration: animationDuration,
        ease: softEaseIn,
        delay: animationDelay,
      }
    );

    if (isSmallDesktop) {
      animate(
        smallImageRef.current,
        {
          bottom: ['594px', '50px'],
          left: ['438px', '72px'],
          opacity: [1, 0.6],
        },
        {
          duration: animationDuration,
          delay: animationDelay,
          ease: softEaseIn,
        }
      );
    } else {
      animate(
        smallImageRef.current,
        { top: ['35%', '30%'], left: ['25px', '10px'], opacity: [1, 0] },
        {
          duration: animationDuration,
          delay: animationDelay,
          ease: softEaseIn,
        }
      );
    }

    if (isSmallDesktop) {
      animate(
        bigImageRef.current,
        {
          bottom: ['275px', '300px'],
          right: ['306px', '80px'],
          opacity: [1, 0.5],
        },
        {
          duration: animationDuration,
          delay: animationDelay,
          ease: softEaseIn,
        }
      );
    } else {
      animate(
        bigImageRef.current,
        { bottom: ['27%', '20%'], right: ['25px', '10px'], opacity: [1, 0] },

        {
          duration: animationDuration,
          delay: animationDelay,
          ease: softEaseIn,
        }
      );
    }

    animate(
      titleCardRef.current,
      { y: ['-100%', '0'] },
      {
        duration: animationDuration,
        delay: animationDelay,
        ease: softEaseIn,
        onComplete: () => {
          setIsAnimating(false);
          setScrollPosition('above');
          setElementInView('card');
        },
      }
    );
  };

  const handleScroll = (event: WheelEvent) => {
    const delta = event.deltaY;

    if (isAnimating) {
      event.preventDefault();

      return;
    }

    if (self.current) {
      const rect = self.current.getBoundingClientRect();

      if (rect.bottom < 0) {
        setScrollPosition('below');
      }

      if (rect.top >= window.innerHeight) {
        setScrollPosition('above');
      }
    }

    if (selfIsInView && scrollPosition === 'above' && elementInView === null && delta > 0) {
      lockScroll();
      ScrollAnimator.smoothScrollToElement(self.current, {
        duration: 100,
        delay: 1200,
        easingFunction: SCROLL_EASING_FUNCTIONS.fastInSlowOut,
        onComplete: () => {
          setElementInView('card');
        },
      });
    } else if (selfIsInView && scrollPosition === 'below' && elementInView === null && delta < 0) {
      lockScroll();
      ScrollAnimator.smoothScrollToElement(self.current, {
        duration: 100,
        delay: 1200,
        easingFunction: SCROLL_EASING_FUNCTIONS.fastInSlowOut,
        onComplete: () => {
          setElementInView('description');
        },
      });
    }

    if (elementInView === 'card') {
      if (delta > 0 && selfIsInView) {
        handleCardToDescription();
      } else {
        unlockScroll();
        setElementInView(null);
      }
    } else if (elementInView === 'description') {
      if (delta < 0 && selfIsInView) {
        handleDescriptionToCard();
      } else {
        unlockScroll();
        setElementInView(null);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [scrollPosition, isAnimating, selfIsInView, self, elementInView]);

  return {
    refs: { self, titleCardRef, descriptionRef, smallImageRef, bigImageRef },
  };
};
