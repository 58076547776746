import fallbackImage1 from 'src/images/fallback/fallback-image-1.jpg';
import fallbackImage2 from 'src/images/fallback/fallback-image-2.jpg';
import fallbackImage3 from 'src/images/fallback/fallback-image-3.png';

import { TextAndCardProps } from './TextAndCard';

export const textAndCardMock: TextAndCardProps = {
  title: 'Access high-quality carbon credits',
  subTitle:
    'Amazon has collaborated with scientists, NGOs, and thought leaders to develop an industry-leading approach to using carbon credits to fund climate solutions.',
  cardTitle: 'Eligibility criteria',
  cardCopy: {
    firstParagraph:
      'We’re now offering qualified business customers and suppliers a way to neutralize carbon emissions at scale by purchasing high-quality carbon credits through Amazon. Signatories of [The Climate Pledge](https://www.theclimatepledge.com/) are also eligible to purchase credits. ',
    secondParagraph:
      'Participating companies must measure and report greenhouse gas emissions on a regular basis, commit to implementing decarbonization strategies in line with the Paris Agreement, and set a net-zero carbon emissions target by no later than 2050, covering scope 1, 2, and 3 emissions.',
  },
  cardImage: { src: fallbackImage1, alt: 'fallbackImage1' },
  description: {
    grey: 'Today, our investments aim to support three climate priorities: ',
    green: 'reducing deforestation, restoring forests, and advancing technological carbon removal.',
  },
  smallImage: { src: fallbackImage2, alt: 'fallbackImage2' },
  bigImage: { src: fallbackImage3, alt: 'fallbackImage3' },
};
