// This file is used to host our temporary hardcoded previews and transcription to improve readibility in the files using them,
// once we have hosted assets and final strings we can add translation keys as usual and get rid of this

export const heroBannerSlideshowImages = [
  {
    imageSrc: 'https://picsum.photos/id/1/1200/1200',
  },
  {
    imageSrc: 'https://picsum.photos/id/2/1200/1200',
  },
  {
    imageSrc: 'https://picsum.photos/id/3/1200/1200',
  },
  {
    imageSrc: 'https://picsum.photos/id/4/1200/1200',
  },
];

export const videoPreviews = [
  {
    time: 0,
    thumbnailSrc: 'https://picsum.photos/id/1/200/300',
    text: 'Preview dynamic information 1',
    thumbnailAlt: 'Describing thumbnail 1',
  },
  {
    time: 11,
    thumbnailSrc: 'https://picsum.photos/id/2/200/300',
    text: 'Preview dynamic information 2',
    thumbnailAlt: 'Describing thumbnail 2',
  },
  {
    time: 30,
    thumbnailSrc: 'https://picsum.photos/id/3/200/300',
    text: 'Preview dynamic information 3',
    thumbnailAlt: 'Describing thumbnail 3',
  },
  {
    time: 71,
    thumbnailSrc: 'https://picsum.photos/id/4/200/300',
    text: 'Preview dynamic information 4',
    thumbnailAlt: 'Describing thumbnail 4',
  },
];

export const videoTranscription = [
  {
    time: 4,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    time: 45,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    time: 102,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
];
