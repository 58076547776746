import { FunctionComponent, useState } from 'react';

import { AsxImage, BrandTabs, BrandTabsProps, PrimaryButtonV2, Quote } from 'src/components/atoms';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './QuoteComponent.module.scss';

export type QuoteComponentProps = {
  /** The main heading of the component, styled into two parts (e.g., colored differently). */
  title: string;

  /** A secondary heading or description displayed below the title. */
  subtitle: string;

  /** The tab configuration and data, including tab options and their associated content. */
  brandTabs: BrandTabsProps;

  /** An array of quotes corresponding to each tab in `BrandTabs`. The active tab determines the displayed quote. */
  quotes: Array<string>;

  /** The label text for the primary button displayed in the component. */
  buttonLabel: string;

  /** The image displayed on the right side of the component, with `src` for the image URL and `alt` for accessibility. */
  imageRight: ImageAttributes;

  /** The image displayed on the left side of the component, conditionally rendered based on screen size. */
  imageLeft: ImageAttributes;
};

export const QuoteComponent: FunctionComponent<QuoteComponentProps> = ({
  title,
  subtitle,
  brandTabs,
  quotes,
  buttonLabel,
  imageRight,
  imageLeft,
}: QuoteComponentProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const { isSmallDesktop } = useDeviceSize();

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };

  // Split the title into two parts
  const titleWords = title.split(' ');
  const greenPart = titleWords.slice(0, 2).join(' '); // First two words
  const whitePart = titleWords.slice(2).join(' '); // Remaining words

  return (
    <div className={styles.quoteComponent} data-testid="quote-component">
      <div className={styles.topSection}>
        <h2 className={styles.title}>
          <span className={styles.greenTitle}>{greenPart}</span> <span className={styles.whiteTitle}>{whitePart}</span>
        </h2>
        <p className={styles.subtitle}>{subtitle}</p>
        {isSmallDesktop && (
          <AsxImage aria-hidden className={styles.imageLeft} src={imageLeft.src} alt={imageLeft.alt} />
        )}
      </div>
      <BrandTabs {...brandTabs} onTabChange={handleTabChange} className={styles.brandTabs} />
      <Quote key={activeTab} copy={quotes[activeTab]} className={styles.quote} />
      <div className={styles.primaryButtonWrapper}>
        <PrimaryButtonV2 label={buttonLabel} data-testid="primary-button" />
      </div>
      <AsxImage aria-hidden className={styles.imageRight} src={imageRight.src} alt={imageRight.alt} />
    </div>
  );
};
