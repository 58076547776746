import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { useWeblab } from '@amzn/sustainability-central-shared-react';

import { ButtonLink, ButtonLinkVariant } from 'src/components/common/menu-item/button-link/ButtonLink';
import { CloseButton } from 'src/components/common/menu-item/close-button/CloseButton';
import { CopyRight } from 'src/components/common/menu-item/copyright/CopyRight';
import { HamburgerButton } from 'src/components/common/menu-item/hamburger-button/HamburgerButton';
import { Link, LinkStyleVariant } from 'src/components/common/menu-item/link/Link';
import { Logo } from 'src/components/common/menu-item/logo/Logo';
import { Parent, ParentStyleVariant } from 'src/components/common/menu-item/parent/Parent';
import { SearchButtonLink } from 'src/components/common/menu-item/search-button-link/SearchButtonLink';
import { SubNavTitle } from 'src/components/common/menu-item/subnav-title/SubNavTitle';
import { ASX_XCM_BRANDING } from 'src/constants';
import { NAV_ITEM_TYPE, NAV_PLACEMENT } from 'src/enums/navigation';
import { THEME } from 'src/enums/theme';
import { NavItem } from 'src/interfaces/navigation';

import styles from './MenuItem.module.scss';
import { HeadingLink } from './heading-link/HeadingLink';
import { LocalePicker } from './locale-picker/LocalePicker';

interface MenuItemProps {
  isAlignedRight?: boolean;
  isDeviceMenuExpanded?: boolean;
  isItemActive?: boolean;
  isItemExpanded?: boolean;
  isNavExpanded?: boolean;
  theme?: THEME;
  navItem: NavItem;
  onDeviceMenuExpand?: () => void;
  onDeviceMenuCollapse?: () => void;
  onItemActivate?: (navItem: NavItem) => void;
  onMenuExpand?: (navItem: NavItem) => void;
  onMenuCollapse?: () => void;
  placement: NAV_PLACEMENT;
}

const getLinkStyleVariant = (
  isFooter: boolean,
  isSubNav: boolean,
  isNavExpanded: boolean,
  isDeviceNav: boolean,
  isDeviceSubNav: boolean
): LinkStyleVariant => {
  if (isFooter) {
    return 'secondary';
  }

  if (isSubNav) {
    return 'tertiary';
  }

  if (isDeviceNav || isDeviceSubNav) {
    return 'quaternary';
  }

  if (isNavExpanded) {
    return 'secondary';
  }

  return 'primary';
};

const getButtonLinkStyleVariant = (isFooter: boolean, isNavExpanded: boolean): ButtonLinkVariant => {
  if (isFooter) {
    return 'tertiary';
  }

  return isNavExpanded ? 'secondary' : 'primary';
};

const getParentStyleVariant = (
  isNavExpanded: boolean,
  isDeviceNav: boolean,
  isDeviceSubNav: boolean
): ParentStyleVariant => {
  if (isDeviceNav) {
    return 'tertiary';
  }

  if (isDeviceSubNav) {
    return 'quaternary';
  }

  return isNavExpanded ? 'secondary' : 'primary';
};

// TODO: Clean up hardcoded values for launch and use the NRS provided ones instead
// https://taskei.amazon.dev/tasks/ASX-367
const XCM_BRANDING_LOGO_TEXT = 'Sustainability Exchange';
const XCM_BRANDING_LOGO_URL = '/';

// This component's job is to select which menu item it should display and wrap it in an appropriately styled <li />
export const MenuItem: FunctionComponent<MenuItemProps> = ({
  isAlignedRight = false,
  isDeviceMenuExpanded = false,
  isItemActive = false,
  isItemExpanded = false,
  isNavExpanded = false,
  theme = THEME.LIGHT,
  navItem,
  onDeviceMenuCollapse,
  onDeviceMenuExpand,
  onItemActivate,
  onMenuExpand,
  onMenuCollapse,
  placement,
}) => {
  const { itemType, label, url } = navItem;
  const isHeader = placement === NAV_PLACEMENT.HEADER;
  const isFooter = placement === NAV_PLACEMENT.FOOTER;
  const isSubNav = placement === NAV_PLACEMENT.SUB_NAV;
  const isDeviceNav = placement === NAV_PLACEMENT.DEVICE_NAV;
  const isDeviceSubNav = placement === NAV_PLACEMENT.DEVICE_SUB_NAV;
  const linkStyleVariant = getLinkStyleVariant(isFooter, isSubNav, isNavExpanded, isDeviceNav, isDeviceSubNav);
  const isBrandingWeblabOn = useWeblab(ASX_XCM_BRANDING) === 'T1';

  const handleParentClick = () => {
    if (isNavExpanded || isItemExpanded) {
      onMenuCollapse?.();
    } else {
      onMenuExpand?.(navItem);
    }
  };

  const handleMouseEnter = () => {
    onItemActivate?.(navItem);
  };

  const handleHamburgerClick = () => {
    onDeviceMenuExpand?.();
  };

  const handleCloseClick = () => {
    onDeviceMenuCollapse?.();
  };

  const isLogo = itemType === NAV_ITEM_TYPE.LOGO;
  const isCopyRight = itemType === NAV_ITEM_TYPE.COPYRIGHT;
  const isCloseButton = itemType === NAV_ITEM_TYPE.CLOSE;
  const isHamburgerButton = itemType === NAV_ITEM_TYPE.HAMBURGER;
  const isSearch = itemType === NAV_ITEM_TYPE.SEARCH;
  const isSustainabilitySiteLink = itemType === NAV_ITEM_TYPE.SUST_SITE_LINK;
  const isSubNavTitle = itemType === NAV_ITEM_TYPE.SUB_NAV_TITLE;
  const isLocalePicker = itemType === NAV_ITEM_TYPE.LOCALE_PICKER;
  const isDeviceSuppressedInHeader =
    isHeader && !(isLogo || isSearch || isHamburgerButton || isCloseButton || isSustainabilitySiteLink);

  return (
    <li
      className={classNames(
        styles.listItem,
        { [styles.rightAlign]: isAlignedRight },
        { [styles.copyRight]: isCopyRight },
        { [styles.hamburgerButton]: isHamburgerButton },
        { [styles.closeButton]: isCloseButton },
        { [styles.showCloseButton]: isCloseButton && isDeviceMenuExpanded },
        { [styles.deviceSuppressed]: isDeviceSuppressedInHeader },
        { [styles.footer]: isFooter },
        { [styles.sustSiteLinkInHeader]: isHeader && isSustainabilitySiteLink },
        { [styles.subNavTitle]: isSubNav && isSubNavTitle },
        { [styles.isNewBranding]: isBrandingWeblabOn }
      )}
      onMouseEnter={handleMouseEnter}
    >
      {itemType === NAV_ITEM_TYPE.LINK && (
        <Link href={url} styleVariant={linkStyleVariant} text={label} theme={theme} />
      )}

      {itemType === NAV_ITEM_TYPE.PARENT && (
        <Parent
          isExpanded={isItemExpanded}
          onClick={handleParentClick}
          styleVariant={getParentStyleVariant(isNavExpanded, isDeviceNav, isDeviceSubNav)}
          text={label}
          theme={theme}
        />
      )}

      {isLogo && (
        <Logo
          href={url}
          altText={label}
          styleVariant={isNavExpanded || isDeviceMenuExpanded ? 'secondary' : 'primary'}
          theme={theme}
        />
      )}

      {isSustainabilitySiteLink && !isFooter && isBrandingWeblabOn && (
        <HeadingLink
          href={XCM_BRANDING_LOGO_URL}
          text={XCM_BRANDING_LOGO_TEXT}
          variant={isNavExpanded || isDeviceMenuExpanded ? 'secondary' : 'primary'}
          theme={theme}
        />
      )}

      {isSustainabilitySiteLink && isFooter && (
        <ButtonLink
          href={url}
          text={label}
          variant={getButtonLinkStyleVariant(isFooter, isNavExpanded || isDeviceMenuExpanded)}
          theme={theme}
        />
      )}

      {isSustainabilitySiteLink && !isBrandingWeblabOn && !isFooter && (
        <ButtonLink
          href={url}
          text={label}
          variant={getButtonLinkStyleVariant(isFooter, isNavExpanded || isDeviceMenuExpanded)}
          theme={theme}
        />
      )}
      {isCopyRight && <CopyRight text={label} />}

      {itemType === NAV_ITEM_TYPE.EXTERNAL_LINK && (
        <Link
          href={url}
          styleVariant={isFooter || isNavExpanded ? 'secondary' : 'primary'}
          text={label}
          variant="external"
        />
      )}

      {isSearch && !isDeviceMenuExpanded && (
        <SearchButtonLink
          href={url}
          styleVariant={isNavExpanded ? 'secondary' : 'primary'}
          text={label}
          theme={theme}
        />
      )}

      {isItemActive && (
        <div
          className={classNames(styles.activeIndicator, styles[theme], {
            [styles.secondary]: isNavExpanded,
            [styles.logo]: isLogo,
          })}
          data-testid="sss-active-indicator"
        />
      )}

      {isHamburgerButton && !isDeviceMenuExpanded && (
        <HamburgerButton ariaLabel={label} onClick={handleHamburgerClick} theme={theme} />
      )}

      {isLocalePicker && <LocalePicker variant={isNavExpanded || isDeviceMenuExpanded ? 'secondary' : 'primary'} />}

      {isCloseButton && isDeviceMenuExpanded && <CloseButton ariaLabel={label} onClick={handleCloseClick} />}

      {isSubNavTitle && (isSubNav || isDeviceSubNav) && <SubNavTitle text={label} />}
    </li>
  );
};
