import { type Argument } from 'classnames';
import classNames from 'classnames';
import { ReactElement } from 'react';

import { KatCard } from '@amzn/katal-react';

import { PrimaryButton, PRIMARY_BUTTON_SIZE, Tagline } from 'src/components/atoms';
import { AsxImage } from 'src/components/atoms/asx-image/AsxImage';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './RedirectionCard.module.scss';

export interface RedirectionCardProps {
  className?: Argument;
  title?: string;
  tag?: string;
  copy: string;
  image: ImageAttributes;
  link: string;
  target?: string;
  isExternal?: boolean;
  theme?: SECTION_THEME;
}

export function RedirectionCard({
  className,
  tag,
  title,
  copy,
  link,
  target = '_blank',
  isExternal,
  image,
  theme = SECTION_THEME.Light,
}: RedirectionCardProps): ReactElement {
  const { isDesktop } = useDeviceTracker();

  const handleClick = () => {
    if (target === '_blank') {
      window.open(link, '_blank');
    } else {
      window.location.href = link;
    }
  };

  return (
    <KatCard className={classNames(className, styles[theme], styles.redirectionCard)} onClick={handleClick}>
      <div className={styles.cardContent}>
        {isDesktop && <AsxImage className={styles.image} {...image} />}

        <div className={styles.cardBody}>
          {title ? (
            <p className={styles.title}>{title}</p>
          ) : (
            <Tagline className={styles.tagline}>{tag || 'Tools & resources'}</Tagline>
          )}
          <p className={styles.copy}>{copy || 'Identify solutions to help lower your carbon footprint'}</p>
        </div>

        <PrimaryButton
          className={styles.cardCta}
          icon={isExternal ? 'chevronTopRight' : 'chevronRight'}
          iconPosition="after"
          buttonSize={PRIMARY_BUTTON_SIZE.Small}
          link={link}
        />
      </div>
    </KatCard>
  );
}
