import classnames, { type Argument } from 'classnames';
import { useRef, useState, type FC } from 'react';

import { Accordion, ACCORDION_VARIANT } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';

import styles from './AccordionCards.module.scss';

export type AccordionCardsProps = {
  className?: Argument;
  ariaLabel?: string;
  items: Array<{
    title: string;
    text: string;
  }>;
};

export const AccordionCards: FC<AccordionCardsProps> = ({ className, ariaLabel, items }) => {
  // First accordion should be expanded by default according to design
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  const [maxCardHeight, setMaxCardHeight] = useState<number>(0);
  const cardHeights = useRef<Array<number>>([]);

  const onAccordionClick = (index: number): void => {
    setActiveIndex((previousValue) => (index === previousValue ? null : index));
  };

  const onCardHeightUpdate = (height: number, cardIndex: number): void => {
    cardHeights.current[cardIndex] = height;
    setMaxCardHeight(Math.max(...cardHeights.current));
  };

  return (
    <ul className={classnames(styles.accordionCards, className)} aria-label={ariaLabel}>
      {items.map((item, index) => (
        <li key={`${item.title}-${item.text.slice(5)}`}>
          <Accordion
            height={maxCardHeight}
            variant={ACCORDION_VARIANT.Card}
            theme={SECTION_THEME.Light}
            number={index + 1}
            title={item.title}
            content={[{ text: item.text }]}
            isOpen={activeIndex === index}
            onClick={() => onAccordionClick(index)}
            onCardHeightUpdate={(value) => onCardHeightUpdate(value, index)}
          />
        </li>
      ))}
    </ul>
  );
};
