import { type ReactElement } from 'react';

import { Icon } from 'src/components/atoms';

import styles from './SuccessCheckmark.module.scss';

export function SuccessCheckmark(): ReactElement {
  return (
    <div data-testid="success-checkmark" className={styles.wrapper}>
      <Icon name="check" />
    </div>
  );
}
