import classNames from 'classnames';
import { FieldError, FieldValues } from 'react-hook-form';

import { CheckboxDescription, Icon } from 'src/components/atoms';
import checkboxStyles from 'src/components/atoms/checkbox-description/CheckboxDescription.module.scss';
import { TextInput } from 'src/components/form-elements';
import { AdditionalFormInputProps } from 'src/interfaces/AdditionalFormInputProps';

import styles from './CheckboxDescriptionGroup.module.scss';

interface CheckboxDescriptionGroupProps<T, TFieldValues extends FieldValues> {
  label: string;
  options: {
    value: T;
    optionLabel: string;
    checkboxDescription?: string;
  }[];
  onChange: (value: T, isChecked: boolean) => void;
  errors?: FieldError;
  id: string;
  testId: string;
  sublabel?: string;
  showAdditionalInput?: boolean;
  additionalInputProps?: AdditionalFormInputProps<TFieldValues>;
  values?: Array<T>;
  variant?: 'default' | 'modal';
  optionsPrefix?: string;
}

export const CheckboxDescriptionGroup = <T, TFieldValues extends FieldValues>({
  label,
  options,
  onChange,
  errors,
  id,
  testId,
  sublabel,
  showAdditionalInput,
  additionalInputProps,
  values,
  variant = 'default',
  optionsPrefix,
}: CheckboxDescriptionGroupProps<T, TFieldValues>) => (
  <div className={styles.checkboxDescriptionGroupContainer} data-testid={testId}>
    <fieldset>
      <legend className={classNames(styles.label, errors && styles.error)} id={id}>
        {label}
      </legend>
      {sublabel && (
        <div className={styles.subLabelContainer}>
          <Icon name="info" className={classNames(styles.subLabelIcon, errors && styles.error)} />
          <p className={classNames(styles.subLabel, errors && styles.error)}>{sublabel}</p>
        </div>
      )}
      <ul className={classNames(styles.checkboxGroup, styles[variant])} aria-labelledby={id}>
        {variant === 'modal' && optionsPrefix && (
          <div>
            <p className={styles.optionsPrefix}>{optionsPrefix}</p>
          </div>
        )}
        {options.map(({ value, optionLabel, checkboxDescription }, index) => (
          <li key={optionLabel}>
            <CheckboxDescription
              className={classNames(
                index && 'isFirst',
                index === options.length - 1 && checkboxStyles.isLast,
                checkboxStyles[variant]
              )}
              label={optionLabel}
              description={checkboxDescription}
              onChange={(isChecked) => onChange(value, isChecked)}
              error={Boolean(errors)}
              checked={values?.includes(value)}
              key={`${value}-${values?.includes(value)}`}
            />
          </li>
        ))}
        {showAdditionalInput && additionalInputProps && (
          <div className={styles.textInputContainer}>
            <TextInput
              label={additionalInputProps.label}
              inputType="text"
              id={additionalInputProps.id}
              value={additionalInputProps.additionalInputValue}
              {...additionalInputProps.register(additionalInputProps.id, additionalInputProps.registerOptions)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                additionalInputProps.onChange(event.target.value)
              }
              errors={additionalInputProps.error}
            />
          </div>
        )}
      </ul>
      {errors && (
        <div className={styles.errorWrapper}>
          <Icon name="error" className={styles.errorIcon} />
          <p className={styles.errorMessage}>{errors.message}</p>
        </div>
      )}
    </fieldset>
  </div>
);
