import classNames from 'classnames';
import { type ReactElement } from 'react';

import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import styles from './GridSvg.module.scss';

type GridSvgProps = {
  theme?: SECTION_THEME;
  isFormVariant?: boolean;
  className?: string;
};

export const GridSvg = ({ theme = SECTION_THEME.Dark, isFormVariant, className }: GridSvgProps): ReactElement => {
  const { isSmallDesktop } = useDeviceSize();

  return isFormVariant ? (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(styles.grid, styles[theme], styles.isFormVariant, className)}
    >
      {isSmallDesktop ? (
        <g>
          <path d="M32 0V288" stroke="currentColor" />
          <path d="M64 0V288" stroke="currentColor" />
          <path d="M96 0V288" stroke="currentColor" />
          <path d="M128 0V288" stroke="currentColor" />
          <path d="M160 0V288" stroke="currentColor" />
          <path d="M192 0V288" stroke="currentColor" />
          <path d="M224 0V288" stroke="currentColor" />
          <path d="M256 0V288" stroke="currentColor" />
          <path d="M0 32H320" stroke="currentColor" />
          <path d="M0 64H320" stroke="currentColor" />
          <path d="M0 96H320" stroke="currentColor" />
          <path d="M0 128H320" stroke="currentColor" />
          <path d="M0 160H320" stroke="currentColor" />
          <path d="M0 192H320" stroke="currentColor" />
        </g>
      ) : (
        <g>
          <path d="M24.9375 0V219.243" stroke="currentColor" />
          <path d="M49.3047 0V219.243" stroke="currentColor" />
          <path d="M73.6562 0V219.243" stroke="currentColor" />
          <path d="M98.0234 0V219.243" stroke="currentColor" />
          <path d="M122.383 0V219.243" stroke="currentColor" />
          <path d="M146.742 0V219.243" stroke="currentColor" />
          <path d="M171.102 0V219.243" stroke="currentColor" />
          <path d="M0.578125 24.3594H244.182" stroke="currentColor" />
          <path d="M0.578125 48.7227H244.182" stroke="currentColor" />
          <path d="M0.578125 73.0781H244.182" stroke="currentColor" />
          <path d="M0.578125 97.4414H244.182" stroke="currentColor" />
        </g>
      )}
    </svg>
  ) : (
    <svg className={classNames(styles.grid, styles[theme], className)} fill="none" xmlns="http://www.w3.org/2000/svg">
      {isSmallDesktop ? (
        <g>
          <path d="M32 0V288" stroke="currentColor" />
          <path d="M64 0V288" stroke="currentColor" />
          <path d="M96 0V288" stroke="currentColor" />
          <path d="M128 0V288" stroke="currentColor" />
          <path d="M160 0V288" stroke="currentColor" />
          <path d="M192 0V288" stroke="currentColor" />
          <path d="M224 0V288" stroke="currentColor" />
          <path d="M256 0V288" stroke="currentColor" />
          <path d="M288 0V288" stroke="currentColor" />
          <path d="M0 32H320" stroke="currentColor" />
          <path d="M0 64H320" stroke="currentColor" />
          <path d="M0 96H320" stroke="currentColor" />
          <path d="M0 128H320" stroke="currentColor" />
          <path d="M0 160H320" stroke="currentColor" />
          <path d="M0 192H320" stroke="currentColor" />
          <path d="M0 224H320" stroke="currentColor" />
          <path d="M0 256H320" stroke="currentColor" />
        </g>
      ) : (
        <g>
          <path d="M32 0V288" stroke="currentColor" />
          <path d="M64 0V288" stroke="currentColor" />
          <path d="M96 0V288" stroke="currentColor" />
          <path d="M128 0V288" stroke="currentColor" />
          <path d="M160 0V288" stroke="currentColor" />
          <path d="M192 0V288" stroke="currentColor" />
          <path d="M224 0V288" stroke="currentColor" />
          <path d="M0 32H320" stroke="currentColor" />
          <path d="M0 64H320" stroke="currentColor" />
          <path d="M0 96H320" stroke="currentColor" />
          <path d="M0 128H320" stroke="currentColor" />
          <path d="M0 160H320" stroke="currentColor" />
          <path d="M0 192H320" stroke="currentColor" />
        </g>
      )}
    </svg>
  );
};
